/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { v1 } from 'uuid';

import './Delays.style.scss';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Select from 'components/generics/Select/Select';
import Input from 'components/generics/Input/Input';
import Button from 'components/generics/Button/Button';

import { updateForm } from 'utils/func';

import { get as getDelayType } from 'services/API/DelayType';
import { get as getDelays, add as addDelay, del as deleteDelay } from 'services/API/Delay';
import DeleteIcon from 'components/icons/DeleteIcon';

const Delay = ({ flightId }) => {
  const DEFAULT_VALUES = { flightId: flightId, delayTypeId: 0, remark: '' };

  const [formDatas, setFormDatas] = useState(DEFAULT_VALUES);
  const [delayTypes, setDelayTypes] = useState([]);
  const [delays, setDelays] = useState([]);

  // GET DATA
  useEffect(() => {
    getDelayType()
      .then(res => {
        setDelayTypes([...[{ name: 'Select a delay type', id: 0 }], ...res.data]);
      })
      .catch(e => console.error(e));
    getDatas();
  }, []);

  const getDatas = () => {
    getDelays(flightId)
      .then(res => setDelays(res.data))
      .catch(err => console.log(err));
  };

  const onDelete = id => {
    deleteDelay(id)
      .then(() => {
        getDatas();
      })
      .catch(err => console.log(err));
  };

  const onAdd = name => {
    addDelay(formDatas)
      .then(res => {
        setFormDatas(DEFAULT_VALUES);
        getDatas();
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="inputs" id="delays">
      <InputLabel label="DELAY" name="delay">
        <Select
          name="delay"
          options={delayTypes}
          value={formDatas.delayTypeId}
          itemValue="id"
          itemLabel="name"
          className="primary"
          onChange={value => updateForm(formDatas, setFormDatas, 'delayTypeId', +value)}
        />
      </InputLabel>
      <InputLabel label="REMARK" name="delay_remark">
        <Input
          name="delay_remark"
          type="text"
          placeholder=""
          defaultStyle="primary"
          onChange={value => updateForm(formDatas, setFormDatas, 'remark', value)}
          value={formDatas.remark}
        />
      </InputLabel>
      <Button text={'Add'} defaultStyle={`primary less_padding`} onClick={e => onAdd()} />
      <div className="">
        <table className="QuickFlightTable">
          <thead>
            <tr>
              <th scope="col">Delay Type</th>
              <th scope="col">Comment</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {delays.map(v => (
              <tr key={v1()}>
                <td>{v.delayType.name}</td>
                <td>{v.remark}</td>
                <td>
                  <span onClick={() => onDelete(v.id)}>
                    <DeleteIcon fill={'#000'} />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Delay;
