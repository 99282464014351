import React from 'react';
import PropTypes from 'prop-types';

const FlightAnomaliesIcon = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 50 50">
    <defs>
      <clipPath id="clip-path">
        <rect width={width} height={height} fill={fill} color={color} />
      </clipPath>
    </defs>
    <g id="anomalies" transform="translate(-1454.118 -38.684)">
      <g transform="translate(1459.118 43.684)">
        <g clipPath="url(#clip-path)">
          <path
            d="M1471.152,62.551l-5.491-1.1-4.93,4.931,7.77,3.884,3.885,7.771,4.931-4.931-1.1-5.491,1.631-1.393,5.771,5.9a8.244,8.244,0,1,0,9.221-4.548l.68-.68a3.532,3.532,0,0,0-4.862-5.12l-1.785-3.261,6.34-5.418a5.347,5.347,0,1,0-7.539-7.538l-5.413,6.335-3.248-1.8a3.532,3.532,0,0,0-5.137-4.844l-1.286,1.286-4.347-2.408a3.547,3.547,0,0,0-4.213.59l-2.912,2.912,13.432,13.287Zm25.819,13.076a5.887,5.887,0,1,1-5.887-5.886,5.883,5.883,0,0,1,5.887,5.886Zm-6.781-12.063a1.177,1.177,0,0,1,1.666,1.665l-.845.845-1.178-2.153Zm-1.023,4.046a8.2,8.2,0,0,0-4.214,2.509l-5.311-5.427,5.4-4.617Zm-1.7-20.524a2.992,2.992,0,1,1,4.219,4.219l-18.046,15.42,1.122,5.61-1.729,1.729-2.775-5.55-5.551-2.775,1.73-1.73,5.609,1.122Zm-13.925-.172a1.177,1.177,0,1,1,1.666,1.665l-.33.33-2.143-1.187Zm-11.083.705,1.237-1.237a1.182,1.182,0,0,1,1.405-.2l13.6,7.532-4.617,5.4Zm0,0"
            transform="translate(-1459.118 -43.684)"
            fill={fill}
            color={color}
          />
        </g>
      </g>
      <path
        d="M1494.408,77.281l-1.666,1.665,1.523,1.522-1.523,1.523,1.666,1.665,1.522-1.522,1.522,1.522,1.666-1.665-1.522-1.523,1.522-1.522-1.666-1.665-1.522,1.522Zm0,0"
        transform="translate(-4.845 -4.841)"
        fill={fill}
        color={color}
      />
    </g>
  </svg>
);

FlightAnomaliesIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

FlightAnomaliesIcon.defaultProps = {
  color: '#00f',
  fill: '#fff',
  width: 40.207,
  height: 40.207,
};

export default FlightAnomaliesIcon;
