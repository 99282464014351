import { useState } from 'react';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Input from 'components/generics/Input/Input';
import Button from 'components/generics/Button/Button';
import { updateForm, toastErrors } from 'utils/func';
import { toast } from 'react-toastify';

import { requestPassword } from 'services/API/User';

const DEFAULT_FORM = { email: '' };

const ForgotPasswordForm = () => {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [loading, setLoading] = useState(false);

  const onSubmit = e => {
    if (e) e.preventDefault();
    setLoading(true);
    requestPassword(formData)
      .then(res => {
        setLoading(false);
        setFormData(DEFAULT_FORM);
        toast.success('Email sent !');
      })
      .catch(err => {
        toastErrors(err.response.data.message);
        setLoading(false);
      });
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <InputLabel label="Email" name="email">
          <Input
            name="email"
            value={formData.email}
            type="string"
            placeholder="Enter email"
            defaultStyle="primary"
            onChange={value => updateForm(formData, setFormData, 'email', value)}
            disabled={loading}
          />
        </InputLabel>
        <span>
          <Button defaultStyle="d-block primary less_padding" type="submit" text="Send email" disabled={loading} />
        </span>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
