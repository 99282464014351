import { useState } from 'react';

import Bar from 'components/generics/Charts/Bar';

const GlobalSeats = ({ data, loading }) => {
  const [logScale, setLogScale] = useState(false);
  return !loading ? (
    <>
      <div className="highlight_value">
        <div>
          <span>{data?.find(v => v.id === 'Seats used').value.toLocaleString()}</span>
          <span>Seats used</span>
        </div>
        <div>
          <span>{data?.find(v => v.id === 'Seats unused').value.toLocaleString()}</span>
          <span>Seats unused</span>
        </div>
        <div>
          <span>{data?.find(v => v.id === 'Total seats').value.toLocaleString()}</span>
          <span>Total seats</span>
        </div>
        <div>
          <label name="switchLog" className="calcul">
            <input
              name="switchLog"
              type="checkbox"
              onChange={() => {
                setLogScale(!logScale);
              }}
              checked={logScale}
            />
            Logarithmic scale
          </label>
        </div>
      </div>
      <div className="graphContainer">
        <Bar data={data.slice(3, 5)} valueScale={logScale ? 'symlog' : 'linear'} />
      </div>
    </>
  ) : (
    <>loading</>
  );
};

export default GlobalSeats;
