export interface User {
    datasource: string,
    firstName: string,
    lastName: string,
    email: string,
    roleName: string,
    exp: number,
    iat: number,
}

export enum UserRole {
    CPS_ADMIN = "CPSAdmin", //Admin CPS
    CPS_USER = "CPSUser", // Collaborateurs CPS
    ADMIN = "Admin",
    ASO = "ASO", //Autorité civile qui gère le Dakar en Arabie saoudite
    AIRLINE = "Airline", // Les compagnies aériennes locales
    AIRPORT = "Airport",
    DBA = "DBA", //DBA admin
    CREW = "CREW", //crew user
    DISPATCH = "DISPATCH", //Passenger user for mobile
    PASSENGER = "PASSENGER", // DBA user
}