import PropTypes from 'prop-types';

const TakeOffIcon = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 58.57 43.928">
    <g color={color} fill={fill} id="take-off" transform="translate(-1544.901 -114.672)">
      <path
        id="Trac\xE9_119"
        data-name="Trac\xE9 119"
        d="M1554.663,143.958c-6.636,0-9.762-.242-9.762-2.441,0-.476,0-1.679,4.341-4.1l-4.136-6.2a1.22,1.22,0,0,1,.154-1.54l2.44-2.44a1.217,1.217,0,0,1,1.408-.23l9.215,4.61,6.775-3.387a1.22,1.22,0,1,1,1.091,2.182l-7.322,3.66a1.222,1.222,0,0,1-1.09,0l-8.974-4.488-1.11,1.111,4.324,6.489a1.22,1.22,0,0,1-.456,1.762,25.6,25.6,0,0,0-3.668,2.265,51.781,51.781,0,0,0,6.77.312c8.165,0,46.368-14.972,46.368-19.523,0-.945-2.318-2.441-8.542-2.441-6.357,0-14.054,3.512-14.132,3.549a1.222,1.222,0,1,1-1.023-2.219c.335-.151,8.256-3.77,15.155-3.77,9.914,0,10.982,3.411,10.982,4.881C1603.471,129.669,1561.159,143.958,1554.663,143.958Z"
        fill="#03a9f4"
      />
      <path
        id="Trac\xE9_120"
        data-name="Trac\xE9 120"
        d="M1568.085,131.755a1.216,1.216,0,0,1-.6-.161l-17.083-9.761a1.221,1.221,0,0,1-.259-1.924l4.881-4.88a1.217,1.217,0,0,1,1.213-.305l24.4,7.321a1.22,1.22,0,0,1,.278,2.216l-12.2,7.321A1.225,1.225,0,0,1,1568.085,131.755Zm-15.092-11.248,15.075,8.615,9.22-5.532-21.056-6.319Z"
        fill="#03a9f4"
      />
      <path
        id="Trac\xE9_121"
        data-name="Trac\xE9 121"
        d="M1592.492,123.209a1.22,1.22,0,0,1-.857-2.089,12.056,12.056,0,0,1,8.176-2.787,1.22,1.22,0,0,1,0,2.44,9.662,9.662,0,0,0-6.475,2.1A1.221,1.221,0,0,1,1592.492,123.209Z"
        fill="#03a9f4"
      />
      <path
        id="Trac\xE9_122"
        data-name="Trac\xE9 122"
        d="M1587.609,151.279a1.213,1.213,0,0,1-.945-.449l-10.982-13.423a1.221,1.221,0,1,1,1.891-1.544l10.365,12.666,3.134-1.567-3.422-13.694a1.22,1.22,0,0,1,2.367-.59l3.661,14.642a1.222,1.222,0,0,1-.637,1.387l-4.881,2.44A1.223,1.223,0,0,1,1587.609,151.279Z"
        fill="#03a9f4"
      />
      <path id="Trac\xE9_123" data-name="Trac\xE9 123" d="M1572.966,158.6h-26.845a1.22,1.22,0,0,1,0-2.44h26.845a1.22,1.22,0,1,1,0,2.44Z" fill="#03a9f4" />
    </g>
  </svg>
);

TakeOffIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

TakeOffIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 58.57,
  height: 43.928,
};

export default TakeOffIcon;
