/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Input from 'components/generics/Input/Input';
import Select from 'components/generics/Select/Select';
import Button from 'components/generics/Button/Button';

import { updateForm, formatDateForInputs } from 'utils/func';

import { getById as getStageById, add as addStage, edit as editStage } from 'services/API/Stage';
import { get as getAirports } from 'services/API/Airport';

const FormStage = ({ onSubmit, editId, type }) => {
  const DEFAULT_VALUES = {
    name: '',
    date: '',
    departureAirportId: 0,
    arrivalAirportId: 0,
  };

  const [formDatas, setFormDatas] = useState(DEFAULT_VALUES);
  const [airports, setAirports] = useState([]);

  useEffect(() => {
    getAirports()
      .then(res => setAirports([...[{ icaoCode: 'Select an airport', id: 0 }], ...res.data]))
      .catch(e => console.log(e));

    if (type === 'edit') {
      getStageById(editId)
        .then(res => {
          setFormDatas({
            name: res.data.name,
            date: new Date(res.data.date),
            departureAirportId: res.data.departureAirportId,
            arrivalAirportId: res.data.arrivalAirportId,
          });
        })
        .catch(e => console.error(e));
    }
  }, []);

  const validateData = () => {
    if (type === 'edit') {
      console.log(editId);
      editStage(formDatas, editId)
        .then(e => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch(err => console.log(err));
    } else {
      addStage(formDatas)
        .then(e => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch(err => console.log(err));
    }
  };

  const action = () => (type === 'edit' ? 'Edit' : 'Add an');

  return (
    <div className="modal__content">
      <h2>{action()} stage</h2>
      <div className="form-part">
        <InputLabel label="Name" name="name">
          <Input name="name" type="text" onChange={value => updateForm(formDatas, setFormDatas, 'name', value)} value={formDatas.name} />
        </InputLabel>
        <InputLabel label="Stage date" name="date">
          <Input
            name="date"
            type="date"
            onChange={value => {
              updateForm(formDatas, setFormDatas, 'date', new Date(value));
            }}
            value={formatDateForInputs(new Date(formDatas.date))}
          />
        </InputLabel>

        <InputLabel label="From(ICAO)" name="departureAirport">
          <Select
            name="departureAirport"
            options={airports}
            itemValue="id"
            itemLabel="icaoCode"
            onChange={value => updateForm(formDatas, setFormDatas, 'departureAirportId', value)}
            value={formDatas.departureAirportId}
          />
        </InputLabel>
        <InputLabel label="To(ICAO)" name="arrivalAirport">
          <Select
            name="arrivalAirport"
            options={airports}
            itemValue="id"
            itemLabel="icaoCode"
            onChange={value => updateForm(formDatas, setFormDatas, 'arrivalAirportId', value)}
            value={formDatas.arrivalAirportId}
          />
        </InputLabel>
      </div>
      <Button text={`${action()} stage`} disabled={false} defaultStyle={`primary`} onClick={e => validateData(e)} />
    </div>
  );
};

export default FormStage;
