/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Input from 'components/generics/Input/Input';
import Button from 'components/generics/Button/Button';

import { updateForm } from 'utils/func';

import { getById as getAirportById, add as addAirport, edit as editAirport } from 'services/API/Airport';

const FormAirport = ({ onSubmit, editId, type }) => {
  const DEFAULT_VALUES = {
    icaoCode: '',
    civilCode: '',
    taxiingTime: '',
    transferTime: '',
    timeLagUtc: '',
    fuelAvailable: '',
  };

  const [formDatas, setFormDatas] = useState(DEFAULT_VALUES);

  useEffect(() => {
    if (type === 'edit') {
      getAirportById(editId)
        .then(res => {
          setFormDatas({
            id: res.data.id,
            icaoCode: res.data.icaoCode,
            civilCode: res.data.civilCode,
            taxiingTime: res.data.taxiingTime,
            transferTime: res.data.transferTime,
            timeLagUtc: res.data.timeLagUtc,
            fuelAvailable: res.data.fuelAvailable,
          });
        })
        .catch(e => console.error(e));
    }
  }, []);

  const validateData = () => {
    if (type === 'edit') {
      editAirport(formDatas)
        .then(e => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch(err => console.log(err));
    } else {
      addAirport(formDatas)
        .then(e => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch(err => console.log(err));
    }
  };

  const action = () => (type === 'edit' ? 'Edit' : 'Add an');

  return (
    <div className="modal__content">
      <h2>{action()} airport</h2>
      <div className="form-part">
        <InputLabel label="ICAO code" name="icaoCode">
          <Input name="icaoCode" type="string" onChange={value => updateForm(formDatas, setFormDatas, 'icaoCode', value)} value={formDatas.icaoCode} />
        </InputLabel>
        <InputLabel label="Civil name" name="civilCode">
          <Input name="civilCode" type="string" onChange={value => updateForm(formDatas, setFormDatas, 'civilCode', value)} value={formDatas.civilCode} />
        </InputLabel>
        <InputLabel label="Taxi time" name="taxiingTime">
          <Input
            name="taxiingTime"
            type="number"
            onChange={value => updateForm(formDatas, setFormDatas, 'taxiingTime', +value)}
            value={formDatas.taxiingTime || ''}
          />
        </InputLabel>
        <InputLabel label="Transfer time" name="transferTime">
          <Input
            name="transferTime"
            type="number"
            onChange={value => updateForm(formDatas, setFormDatas, 'transferTime', +value)}
            value={formDatas.transferTime || ''}
          />
        </InputLabel>
        <InputLabel label="Time lag(UTC)" name="timeLagUtc">
          <Input
            name="timeLagUtc"
            type="number"
            onChange={value => updateForm(formDatas, setFormDatas, 'timeLagUtc', +value)}
            value={formDatas.timeLagUtc || ''}
          />
        </InputLabel>
        <InputLabel label="Fuel available" name="fuel">
          <Input
            name="fuel"
            type="number"
            onChange={value => updateForm(formDatas, setFormDatas, 'fuelAvailable', +value)}
            value={formDatas.fuelAvailable || ''}
          />
        </InputLabel>
      </div>
      <Button text={`${action()} airport`} disabled={false} defaultStyle={`primary`} onClick={e => validateData(e)} />
    </div>
  );
};

export default FormAirport;
