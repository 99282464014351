/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import useAuth from 'providers/Auth/useAuth';

import QuickViewItem from 'components/dashboard/QuickViewItem/QuickViewItem';
import QuickFlights from 'components/dashboard/QuickFlights/QuickFlights';

import { get as getAnomaliesFlights } from 'services/API/Anomalies';

import { isAuthorised } from 'utils/func';

import FlightIcon from 'components/icons/FlightIcon';
import CalendarIcon from 'components/icons/CalendarIcon';
import FlightAnomaliesIcon from 'components/icons/FlightAnomaliesIcon';
// import FuelIcon from 'components/icons/FuelIcon';
import './Dashboard.style.scss';

const Dashboard = () => {
  const [todayFlightCount, setTodayFlightCount] = useState('');
  const [tomorrowFlightCount, setTomorrowFlightCount] = useState('');
  const [flightsAnomalies, setFlightsAnomalies] = useState([]);
  // const [fuelAnomaliesCount, setFuelAnomaliesCount] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    isAuthorised(['Admin', 'CPSAdmin', 'CPSUser'], user.roleName) &&
      getAnomaliesFlights()
        .then(response => {
          setFlightsAnomalies(response.data);
        })
        .catch(err => toast.error(err.message));
  }, []);

  return (
    <div id="dashboard">
      <h1>Dashboard</h1>
      <h2>{user?.datasource.replace('_', ' ')}</h2>
      <div className="quickViewItemsContainer">
        <QuickViewItem title={'TODAY FLIGHT'} value={+todayFlightCount} unit={'Flights'} path={'/flights'} icon={<FlightIcon />} />
        <QuickViewItem
          title={'TOMORROW FLIGHT'}
          value={+tomorrowFlightCount}
          unit={'Flights'}
          path={'/flights'}
          icon={<CalendarIcon width={10} height={10} />}
        />
        {isAuthorised(['Admin', 'CPSAdmin', 'CPSUser'], user.roleName) && (
          <QuickViewItem
            title={'FLIGHTS ANOMALIES'}
            value={flightsAnomalies.length}
            unit={'Flights'}
            path={`/flights/anomalies/${flightsAnomalies.map(d => d.entityId).join(',')}`}
            icon={<FlightAnomaliesIcon />}
          />
        )}
        {/* <QuickViewItem
          title={'TOMORROW FLIGHT'}
          value={6}
          unit={'Flights'}
          path={'/stats'}
          icon={<FuelIcon />}
        /> */}
      </div>
      <QuickFlights date={new Date()} title="Today Flight" returnValue={v => setTodayFlightCount(v)} />
      <QuickFlights date={new Date(+new Date() + 1000 * 60 * 60 * 24)} title="Tomorrow Flight" returnValue={v => setTomorrowFlightCount(v)} />
    </div>
  );
};

export default Dashboard;
