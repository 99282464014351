import API from 'services/API';

export const get = () => API.get('/airline');

export const getById = id => API.get(`/airline/${id}`);

export const add = airline => API.post('/airline', airline);

export const edit = airline => API.patch(`/airline/${airline.id}`, airline);

export const bulkDelete = airline => API.delete('/airline/byBulk', { data: airline });
