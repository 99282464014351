import { ResponsivePie } from '@nivo/pie';

const Pie = ({ data, colors, label = 'label', id = 'id', value = 'value' }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: 80, bottom: 90, left: 80 }}
    colors={colors}
    id={id}
    value={value}
    innerRadius={0.4}
    enableArcLinkLabels={false}
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 0,
        itemsSpacing: 0,
        itemWidth: 90,
        itemHeight: 18,
        itemTextColor: '#000',
        itemDirection: 'top-to-bottom',
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: 'square',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ]}
  />
);

export default Pie;
