/* eslint-disable import/no-anonymous-default-export */
import API from 'services/API';

export const getFuelData = () => API.get('/stat/fuel-data');

export const getClassDelays = () => API.get('/stat/delay-data-by-class');

export const getTypeDelays = () => API.get('/stat/delay-data-by-type');

export const getUsedSeat = () => API.get('/stat/seat-available-data-by-class');

export const getGlobalSeat = () => API.get('/stat/seat-global-study-data');

export const getDateMissionSeat = () => API.get('/stat/seat-data-by-date-and-mission');

export const getMissions = () => API.get('/stat/mission-data');

export const getFlightTimes = () => API.get('/stat/aircraft-flight-time-data');

export const getAircraftCost = () => API.get('stat/aircraft-flight-cost');
