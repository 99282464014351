/* eslint-disable react-hooks/exhaustive-deps */
// TODO ADD LOADING
// Fix triple rerender
import React, { useEffect, useMemo, useState } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';

import './reactTable.style.scss';
import { v1 } from 'uuid';
import DisplayColumnIcon from 'components/icons/DisplayColumnIcon';
import EditIcon from 'components/icons/EditIcon';
import Select from '../Select/Select';
import Button from '../Button/Button';

import { convertDateToUTC, getFromLocalStorage, saveToLocalStorage } from 'utils/func';
import { DAYS_MILLISECONDS } from 'utils/const';
import useAuth from 'providers/Auth/useAuth';

const Table = ({ selectedRows, datas, headers, editable = false, onEdit, filters = true, selectable = true, name = 'default' }) => {
  const user = useAuth();
  // CLOSE POPUP ON CLICK OUTSIDE
  const [filterPopupVisibility, setFilterPopupVisibility] = useState(false);
  const [UTCDiff, setUTCDiff] = useState(false);

  const data = useMemo(() => datas, [datas]);

  const columns = useMemo(() => headers, [headers]);

  const {
    getTableProps,
    getTableBodyProps,
    allColumns,
    headerGroups,
    selectedFlatRows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    visibleColumns,
    setHiddenColumns,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 30, pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      selectable &&
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),

            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    },
  );

  useEffect(() => {
    selectedRows(selectedFlatRows);
  }, [selectedFlatRows, selectedRows]);

  useEffect(() => {
    if (filters) {
      const headersId = headers.map(e => e.accessor);
      const storedHiddenColumns = getFromLocalStorage(name);
      storedHiddenColumns && setHiddenColumns(storedHiddenColumns.filter(value => headersId.includes(value)));
    } else {
      setHiddenColumns(['id']);
    }
    // TODO: END LOADING
  }, [headers]);

  useEffect(() => {
    setUTCDiff(((+convertDateToUTC(new Date()) % DAYS_MILLISECONDS) / 1000 / 60).toFixed(0));
  }, []);

  /**
   * Rules for react table rows
   * @param {Object} row react row prepared datas
   * @returns
   * SPECS :
   * - Vol qui a décollé : Ligne écrite en bleu
   * - Vol terminated : Ligne en gris
   * - Vol updated avant décollage (champs updatedAt != du champs createdAt) : Ligne en rouge
   * - Vol created : blanc
   */
  const applyRowClass = row => {
    const data = row.values.raw;
    if (data) {
      if (row.values && ['canceledFlightFilters', 'allFlightsFilters', 'flightsAnomaliesFilters', 'flightsFilters'].includes(name)) {
        const date = new Date();
        const splitedFlightDate = row.values.date.split('/');
        const flightDate = new Date(`${splitedFlightDate[1]}/${splitedFlightDate[0]}/${splitedFlightDate[2]}`);
        if(data?.flightStatusId === 5) {
          return ''
        }
        if (data?.flightStatusId === 3) {
          return 'cancelled-flight';
        } else {
          if (flightDate >= new Date(date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate())) {
            let takeOff = (row.values.takeoffTimeReal ? row.values.takeoffTimeReal?.split(':') : row.values.takeoffTimeRally?.split(':')).map(v => +v);
            let landing = row.values.landingTimeReal?.split(':');
            let flightToday =
              flightDate.getFullYear() === date.getFullYear() && flightDate.getMonth() + 1 === date.getMonth() + 1 && flightDate.getDate() === date.getDate();
            if (flightDate >= new Date(date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate())) {
              if (flightToday && (!landing || (landing[0] !== '--' && landing[1] !== '--'))) {
                return 'past-flight';
              } else {
                if (flightToday && (takeOff[0] < date.getHours() || (takeOff[0] === date.getHours() && takeOff[1] < date.getMinutes()))) {
                  return 'in-flight';
                } else {
                  if (!data.hasBeenRead) {
                    return 'updated-flight';
                  } else {
                    return '';
                  }
                }
              }
            }
          } else {
            return 'past-flight';
          }
        }
      }
    }
    return '';
  };

  useEffect(() => {
    if (data.length > 0) {
      // switch with loadingState
      const headersId = headers.map(e => e.accessor);
      const visibles = visibleColumns.map(e => e.id);
      const hiddens = headersId.filter(v => !visibles.includes(v));
      saveToLocalStorage(name, hiddens);
    }
  }, [visibleColumns]);

  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  });

  return (
    <div className="table">
      {filters && (
        <div className="table__filter">
          <div className="table__toggle-filter" onClick={() => setFilterPopupVisibility(!filterPopupVisibility)}>
            <DisplayColumnIcon />
            FILTER
          </div>
          {filterPopupVisibility && (
            <div className="options">
              {allColumns
                .filter(r => r.Header !== 'raw')
                .map(
                  column =>
                    column.id !== 'selection' && (
                      <label key={column.id}>
                        <input type="checkbox" {...column.getToggleHiddenProps()} />
                        <span>{column.Header}</span>
                      </label>
                    ),
                )}
            </div>
          )}
        </div>
      )}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={v1()}>
              {headerGroup.headers
                .filter(r => r.Header !== 'raw')
                .map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                ))}
              {editable && <th>Edit</th>}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={applyRowClass(row)}>
                {row.cells
                  .filter(r => r.column.Header !== 'raw')
                  .map(cell => (
                    <td className={`${row.values?.raw?.passengerCapacity <= 5 && user?.user?.roleName == 'ASO' ? 'italic' : ''}`} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                {editable && (
                  <td className="icon">
                    <span onClick={() => onEdit(row.values)}>
                      <EditIcon fill="#000" />
                    </span>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="table__footer">
        <span>{data.length} rows</span>
        {data.length > pageSize && (
          <span className="table__footer-pagination">
            <Button text="<" disabled={!canPreviousPage} defaultStyle={`primary less_padding${!canPreviousPage ? ' disabled' : ''}`} onClick={previousPage} />
            <span>
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
            <Button text=">" disabled={!canNextPage} defaultStyle={`primary less_padding${!canNextPage ? ' disabled' : ''}`} onClick={nextPage} />
          </span>
        )}
        <span>
          <Select
            options={[10, 20, 30, 40, 50].map(v => ({ value: v, label: `Show ${v}` }))}
            itemValue="value"
            itemLabel="label"
            onChange={value => setPageSize(+value)}
            value={pageSize}
          />
        </span>
      </div>
    </div>
  );
};

export default Table;
