import './Stats.style.scss';

import Tabs from 'components/generics/Tabs/Tabs';
import FuelStats from 'components/pages/Stats/Fuel/FuelStats';
import DelayStats from 'components/pages/Stats/Delay/DelayStats';
import SeatStats from 'components/pages/Stats/Seat/SeatStats';
import MissionsStats from 'components/pages/Stats/Missions/MissionsStats';
import FlightTimeStats from 'components/pages/Stats/FlightTimes/FlightTimesStats';
import useAuth from 'providers/Auth/useAuth';
import { AircraftCost } from './AircraftCost/AircraftCost';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import RoleGuard from '../../generics/RoleGuard/RoleGuard';
import { UserRole } from '../../../utils/interface/user';

const Stats = () => {
  let { path, url } = useRouteMatch();
  const { user } = useAuth();
  return (
    <div id="stats">
      <div>
        <h1>Statistics</h1>
        <h2>{user?.datasource.replace('_', ' ')}</h2>
      </div>
      <Tabs url={url} />
      <Switch>
        <Route exact path={[path, `${path}/delays`]}>
          <DelayStats />
        </Route>
        <Route path={`${path}/seats`}>
          <SeatStats />
        </Route>
        <Route path={`${path}/fuel`}>
          <FuelStats />
        </Route>
        <Route path={`${path}/missions`}>
          <MissionsStats />
        </Route>
        <Route path={`${path}/flightTime`}>
          <FlightTimeStats />
        </Route>
        <RoleGuard allowedRoles={[UserRole.CPS_ADMIN]}>
          <Route path={`${path}/aircraftCost`}>
            <AircraftCost />
          </Route>
        </RoleGuard>
      </Switch>
    </div>
  );
};

export default Stats;
