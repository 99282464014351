/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import InputLabel from 'components/generics/InputLabel/InputLabel';
import Input from 'components/generics/Input/Input';
import Select from 'components/generics/Select/Select';
import Button from 'components/generics/Button/Button';
import { updateForm } from 'utils/func';
import { add as addAircraft, edit as editAircraft, getById as getAircraftById } from 'services/API/Aircraft';
import { get as getAirlines } from 'services/API/Airline';
import { get as getAircraftType } from 'services/API/AircraftType';
import { FormMode } from '../../../../utils/interface/formMode';
import { Airline } from '../../../../utils/interface/airline';
import { AircraftType } from '../../../../utils/interface/aircraft';

const FormAircraft = ({ onSubmit, editId, type }: { onSubmit: any; editId: number; type: FormMode }) => {
  const DEFAULT_VALUES = {
    reg: '',
    callsign: '',
    squawk: '',
    passengerCapacity: null,
    aircraftTypeId: null,
    airlineId: null,
    contractPlannedHours: null,
    contractCostPerHour: null,
  };

  const [formDatas, setFormDatas] = useState(DEFAULT_VALUES);
  const [aircraftType, setAircraftType] = useState<AircraftType[]>([]);
  const [airlines, setAirlines] = useState<Airline[]>([]);

  useEffect(() => {
    getAirlines()
      .then(res => setAirlines([...[{ name: 'Select an Airline', id: 0 }], ...res.data]))
      .catch(e => console.log(e));

    getAircraftType()
      .then(res => setAircraftType([...[{ name: 'Select aircraft type', id: 0 }], ...res.data]))
      .catch(e => console.log(e));

    if (type === FormMode.UPDATE) {
      getAircraftById(editId)
        .then(res => {
          delete res.data.aircraftType;
          delete res.data.airline;
          setFormDatas({ ...res.data });
        })
        .catch(e => console.error(e));
    }
  }, []);

  const validateData = () => {
    if (type === FormMode.UPDATE) {
      editAircraft(formDatas)
        .then(e => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch(err => console.log(err));
    } else {
      addAircraft(formDatas)
        .then(e => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch(err => console.log(err));
    }
  };

  const action = () => (type === FormMode.UPDATE ? 'Edit' : 'Add an');

  return (
    <div className="modal__content">
      <h2>{action()} aircraft</h2>
      <div className="form-part">
        <InputLabel label="Aircraft Type" name="aircraftType">
          <Select
            name="aircraftType"
            options={aircraftType}
            value={formDatas.aircraftTypeId}
            itemValue="id"
            itemLabel="name"
            onChange={value => updateForm(formDatas, setFormDatas, 'aircraftTypeId', +value)}
          />
        </InputLabel>
        <InputLabel label="Reg" name="reg">
          <Input name="reg" type="string" onChange={value => updateForm(formDatas, setFormDatas, 'reg', value)} value={formDatas.reg} />
        </InputLabel>
        <InputLabel label="PAX" name="passengerCapacity">
          <Input
            name="passengerCapacity"
            type="number"
            onChange={value => updateForm(formDatas, setFormDatas, 'passengerCapacity', value)}
            value={formDatas.passengerCapacity!}
          />
        </InputLabel>
        <InputLabel label="Airline" name="Airline">
          <Select
            name="Airline"
            options={airlines}
            value={formDatas.airlineId}
            itemValue="id"
            itemLabel="name"
            onChange={value => updateForm(formDatas, setFormDatas, 'airlineId', +value)}
          />
        </InputLabel>
        <InputLabel label="Callsign" name="callsign">
          <Input name="callsign" type="string" onChange={value => updateForm(formDatas, setFormDatas, 'callsign', value)} value={formDatas.callsign} />
        </InputLabel>
        <InputLabel label="Squawk" name="squawk">
          <Input name="squawk" type="string" onChange={value => updateForm(formDatas, setFormDatas, 'squawk', value)} value={formDatas.squawk} />
        </InputLabel>
        <InputLabel label="Contract Planned Hours" name="contractPlannedHours">
          <Input
            name="contractPlannedHours"
            type="number"
            onChange={value => updateForm(formDatas, setFormDatas, 'contractPlannedHours', value)}
            value={formDatas.contractPlannedHours!}
          />
        </InputLabel>
        <InputLabel label="Contract Cost Per Hour" name="contractCostPerHour">
          <Input
            name="contractCostPerHour"
            type="number"
            onChange={value => updateForm(formDatas, setFormDatas, 'contractCostPerHour', value)}
            value={formDatas.contractCostPerHour!}
          />
        </InputLabel>
      </div>
      <Button text={`${action()} aircraft`} disabled={false} defaultStyle={`primary`} onClick={e => validateData()} />
    </div>
  );
};

export default FormAircraft;
