/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Input from 'components/generics/Input/Input';
import Select from 'components/generics/Select/Select';
import Button from 'components/generics/Button/Button';
import Delay from 'components/pages/Flights/Delays';
import {
  formatDateForInputs,
  formatMinutesToReadableHourOfDay,
  formatReadableHourToMinutes,
  updateForm,
} from 'utils/func';
import { DAYS_MINUTES } from 'utils/const';

import { get as getAirports } from 'services/API/Airport';
import { get as getMissions } from 'services/API/Mission';
import { get as getAircrafts } from 'services/API/Aircraft';
import { get as getAircraftTypes } from 'services/API/AircraftType';
import { get as getRoutes } from 'services/API/Route';
import { get as getFlightStatus } from 'services/API/FlightStatus';
import { add as addFlight, edit as editFlight, getById as getFlightsById } from 'services/API/Flight';

import FuelIcon from 'components/icons/FuelIcon';
import StatusIcon from 'components/icons/StatusIcon';
import DateIcon from 'components/icons/DateIcon';
import TakeOffIcon from 'components/icons/TakeOffIcon';
import LandingIcon from 'components/icons/LandingIcon';
import TLBTimeIcon from 'components/icons/TBLTimeIcon';
import DelayIcon from 'components/icons/DelayIcon';

const FlightForm = ({onSubmit, editId, type}) => {
    const DEFAULT_VALUES = {
        date: +new Date(),
        departureAirportId: 0,
        takeoffTimeUtc: '00:00',
        arrivalAirportId: 0,
        landingTimeUtc: '00:00',
        missionId: 0,
        aircraftId: 0,
        passengerCapacity: 0,
        flightStatusId: 2,
        remark: '',
        calculateLandingTime: true,
        passengerPlanned: 0,
    };

    const [formDatas, setFormDatas] = useState(DEFAULT_VALUES);
    const [aircrafts, setAircrafts] = useState([]);
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [airports, setAirports] = useState([]);
    const [missions, setMissions] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [flightStatus, setFlightStatus] = useState([]);
    const [entityToLoad] = useState(['edit', 'editASO'].includes(type) ? 7 : 5);
    const [loading, setLoading] = useState(true);

    let loadedEntity = 0;

    const entityLoaded = () => {
        loadedEntity++;
        if (loadedEntity === entityToLoad) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (
            formDatas.calculateLandingTime &&
            formDatas.departureAirportId !== 0 &&
            formDatas.arrivalAirportId !== 0 &&
            formDatas.aircraftId !== 0 &&
            formDatas.departureAirportId !== formDatas.arrivalAirportId &&
            !loading
        ) {
            const aircraftTypeId = aircraftTypes.filter(
                v =>
                    v.id ===
                    aircrafts.filter(t => t.id === formDatas.aircraftId)[0]
                        .aircraftTypeId
            )[0].id;
            const matchingRoutes = retrieveRoute(
                +formDatas.departureAirportId,
                +formDatas.arrivalAirportId,
                aircraftTypeId);

            if (matchingRoutes.length > 0) {
                updateForm(
                    formDatas,
                    setFormDatas,
                    'landingTimeUtc',
                    formatMinutesToReadableHourOfDay(
                        (formatReadableHourToMinutes(formDatas.takeoffTimeUtc) +
                            +matchingRoutes[0].duration) %
                        DAYS_MINUTES),
                    );
                }
            }
        }
    , [
        formDatas.departureAirportId,
        formDatas.arrivalAirportId,
        formDatas.takeoffTimeUtc,
        formDatas.aircraftId,
        formDatas.calculateLandingTime,
        loading
    ]);

    const retrieveRoute = (from, to, aircraft) =>
        routes
            .filter(v => v.aircraftTypeId === aircraft)
            .filter(
                v => v.departureAirportId === from || v.arrivalAirportId === from
            )
            .filter(v => v.arrivalAirportId === to || v.departureAirportId === to);

    useEffect(() => {
        // todo: use promise.all
        getAirports()
            .then(resAirports => {
                setAirports([
                    ...[{icaoCode: 'Select an airport', id: 0}],
                    ...resAirports.data]);

                entityLoaded();
            })
            .catch(e => toast.error(e.message));
        getMissions()
            .then(resMissions => {
                setMissions([
                    ...[{name: 'Select a mission', id: 0}],
                    ...resMissions.data]);

                entityLoaded();
            })
            .catch(e => toast.error(e.message));
        getAircrafts()
            .then(resAircrafts => {
                setAircrafts([
                    ...[{reg: 'Select an aircraft', id: 0}],
                    ...resAircrafts.data
                ]);
                entityLoaded();
            })
            .catch(e => toast.error(e.message));
        getRoutes()
            .then(resRoutes => {
                setRoutes(resRoutes.data);
                entityLoaded();
            })
            .catch(e => toast.error(e.message));
        getAircraftTypes()
            .then(resAircraftTypes => {
                setAircraftTypes(resAircraftTypes.data);
                entityLoaded();
            })
            .catch(e => toast.error(e.message));

        if (['edit', 'editASO', 'editCREW'].includes(type)) {
            getFlightsById(editId)
                .then(resFlight => {
                    let formatedData = {
                        ...{
                            id: resFlight.data.id,
                            date: new Date(resFlight.data.date),
                            takeoffTimeUtc: formatMinutesToReadableHourOfDay(
                                +resFlight.data.takeoffTimeUtc
                            ),
                            landingTimeUtc: formatMinutesToReadableHourOfDay(
                                +resFlight.data.landingTimeUtc),

                            landingTimeTlb: formatMinutesToReadableHourOfDay(
                                +resFlight.data.landingTimeTlb),

                            takeoffTimeTlb: formatMinutesToReadableHourOfDay(
                                +resFlight.data.takeoffTimeTlb
                            ),
                            flightTimeTlb: formatMinutesToReadableHourOfDay(
                                +resFlight.data.flightTimeTlb
                            ),
                            landingTimeReal: formatMinutesToReadableHourOfDay(
                                +resFlight.data.landingTimeReal),

                            takeoffTimeReal: formatMinutesToReadableHourOfDay(
                                +resFlight.data.takeoffTimeReal
                            ),
                            remark: resFlight.data.remark,
                            passengerCapacity: resFlight.data.passengerCapacity,
                            departureAirportId: resFlight.data.departureAirportId,
                            arrivalAirportId: resFlight.data.arrivalAirportId,
                            missionId: resFlight.data.missionId,
                            aircraftId: resFlight.data.aircraftId,
                            passengerCounter: resFlight.data.passengerCounter,
                            fuelOnDeparture: resFlight.data.fuelOnDeparture,
                            fuelOnArrival: resFlight.data.fuelOnArrival,
                            flightStatusId: resFlight.data.flightStatusId,
                            passengerPlanned: resFlight.data.passengerPlanned,
                        },
                    };
                    setFormDatas(formatedData);
                    entityLoaded();
                })
                .catch(e => toast.error(e.message));
            getFlightStatus()
                .then(resFlightStatus => {
                    setFlightStatus(resFlightStatus.data);
                    entityLoaded();
                })
                .catch(e => toast.error(e.message));
        }
    }, []);

    const prepareDate = date =>
        `${date.getFullYear()}-${(date.getMonth() % 12) + 1}-${date.getDate()}`;

    const validateData = () => {
        if (type === 'edit' || type === 'editCREW') {
            if (
                formDatas.departureAirportId !== 0 &&
                formDatas.arrivalAirportId !== 0 &&
                formDatas.missionId !== 0 &&
                formDatas.aircraftId !== 0) {

                let formatedData = {
                    ...formDatas,
                    ...{
                        date: prepareDate(new Date(formDatas.date)),
                        takeoffTimeUtc: formatReadableHourToMinutes(
                            formDatas.takeoffTimeUtc),

                        landingTimeUtc: formatReadableHourToMinutes(
                            formDatas.landingTimeUtc),

                        landingTimeTlb: formatReadableHourToMinutes(
                            formDatas.landingTimeTlb
                        ),
                        takeoffTimeTlb: formatReadableHourToMinutes(
                            formDatas.takeoffTimeTlb
                        ),
                        flightTimeTlb: formatReadableHourToMinutes(
                            formDatas.flightTimeTlb
                        ),
                        landingTimeReal: formatReadableHourToMinutes(
                            formDatas.landingTimeReal
                        ),
                        takeoffTimeReal: formatReadableHourToMinutes(
                            formDatas.takeoffTimeReal),
                        },
                    };

                delete formatedData.id;
                delete formatedData.calculateLandingTime;
                delete formatedData.flightTimeTlb; // api calculated
                editFlight(formatedData, formDatas.id)
                    .then(res => {
                        toast.success('Flight edited');
                        setFormDatas(DEFAULT_VALUES);
                        onSubmit(formDatas);
                    })
                    .catch(err => toast.error(err.message));
            } else {
                toast.error('Fields missing'); // TODO: improve message
            }
        } else {
            if (
                formDatas.departureAirportId !== 0 &&
                formDatas.arrivalAirportId !== 0 &&
                formDatas.missionId !== 0 &&
                formDatas.aircraftId !== 0) {

                let formatedData = {
                    ...formDatas,
                    takeoffTimeUtc: formatReadableHourToMinutes(
                        formDatas.takeoffTimeUtc
                    ),
                    landingTimeUtc: formatReadableHourToMinutes(
                        formDatas.landingTimeUtc
                    ),
                    hasBeenRead: true,
                    date: prepareDate(new Date(formDatas.date))
                };
                delete formatedData.calculateLandingTime;
                addFlight(formatedData)
                    .then(res => {
                        toast.success('Flight added');
                        setFormDatas(DEFAULT_VALUES);
                        onSubmit(formDatas);
                    })
                    .catch(err => toast.error(err.message));
            } else {
                toast.error('Fields missing'); // TODO: improve message
            }
        }
    };

  const action = () => (type === 'edit' || type === 'editASO' || type === 'editCREW' ? 'Edit' : 'Add a');

    return !loading ? (
        <div className="modal__content">
            <h2>{action()} flight</h2>
            <div className="form-part part-one">
                <div>
          <span>
            <DateIcon/>
            <h3>CALENDAR</h3>
          </span>
                    <InputLabel label="DATE" name="date">
                        <Input
                            name="date"
                            type="date"
                            onChange={value =>
                                updateForm(formDatas, setFormDatas, 'date', +new Date(value))
                            }
                            value={formatDateForInputs(new Date(formDatas.date))}
                            disabled={['editASO', 'editCREW'].includes(type)}
                        />
                    </InputLabel>
                </div>
                <div>
          <span>
            <TakeOffIcon/>
            <h3>DEPARTURE</h3>
          </span>
                    <InputLabel label="FROM (ICAO)" name="departureAirport">
                        <Select
                            name="departureAirport"
                            options={airports}
                            value={formDatas.departureAirportId}
                            itemValue="id"
                            itemLabel="icaoCode"
                            onChange={value =>
                                updateForm(
                                    formDatas,
                                    setFormDatas,
                                    'departureAirportId',
                                    +value)}

                            disabled={['editASO', 'editCREW'].includes(type)}
                        />
                    </InputLabel>
                    <InputLabel label="TAKE OFF TIME (UTC)" name="takeoffTimeUtc">
                        <Input
                            name="takeoffTimeUtc"
                            type="time"
                            placeholder="00:00"
                            onChange={value =>
                                updateForm(formDatas, setFormDatas, 'takeoffTimeUtc', value)}

                            value={formDatas.takeoffTimeUtc}
                            disabled={['editASO', 'editCREW'].includes(type)}
                        />
                    </InputLabel>
                    {type === 'edit' && (
                        <InputLabel label="ACT TAKE OFF" name="takeoffTimeReal">
                            <Input
                                name="takeoffTimeReal"
                                type="time"
                                placeholder="00:00"
                                onChange={value =>
                                    updateForm(formDatas, setFormDatas, 'takeoffTimeReal', value)
                                }
                                value={formDatas.takeoffTimeReal}
                                disabled={['editASO', 'editCREW'].includes(type)}
                            />
                        </InputLabel>
                    )}
                </div>
                <div>
          <span>
            <LandingIcon/>
            <h3>ARRIVAL</h3>
          </span>
                    <InputLabel label="TO (ICAO)" name="arrivalAirport">
                        <Select
                            name="arrivalAirport"
                            options={airports}
                            itemValue="id"
                            itemLabel="icaoCode"
                            onChange={value =>
                                updateForm(formDatas, setFormDatas, 'arrivalAirportId', +value)
                            }
                            value={formDatas.arrivalAirportId}
                            disabled={['editASO', 'editCREW'].includes(type)}
                        />
                    </InputLabel>
                    <InputLabel label="LANDING TIME (UTC)" name="landingTimeUtc">
                        <>
                            <label name="toggleCalcul" className="calcul">
                                <input
                                    name="toggleCalcul"
                                    type="checkbox"
                                    onChange={() =>
                                        updateForm(
                                            formDatas,
                                            setFormDatas,
                                            'calculateLandingTime',
                                            !formDatas.calculateLandingTime
                                        )
                                    }
                                    checked={formDatas.calculateLandingTime}
                                    disabled={['editASO', 'editCREW'].includes(type)}
                                />
                                Auto calcul
                            </label>
                            <Input
                                name="landingTimeUtc"
                                type="time"
                                disabled={
                                    ['editASO', 'editCREW'].includes(type) ||
                                    formDatas.calculateLandingTime
                                }
                                placeholder="00:00"
                                onChange={value =>
                                    updateForm(formDatas, setFormDatas, 'landingTimeUtc', value)
                                }
                                value={formDatas.landingTimeUtc}
                            />
                        </>
                    </InputLabel>
                    {type === 'edit' && (
                        <InputLabel label="ACT LANDING" name="landingTimeReal">
                            <Input
                                name="landingTimeREAL"
                                type="time"
                                placeholder="00:00"
                                onChange={value =>
                                    updateForm(formDatas, setFormDatas, 'landingTimeReal', value)
                                }
                                value={formDatas.landingTimeReal}
                                disabled={['editASO', 'editCREW'].includes(type)}
                            />
                        </InputLabel>
                    )}
                </div>
            </div>
            {['edit', 'editASO', 'editCREW'].includes(type) && (
                <div className="form-part part-edit">
                    <div className="TLB section">
            <span className="title">
              <span>
                <TLBTimeIcon width={25}/>
                TLB TIME
              </span>
            </span>
                        <span className="inputs">
              <InputLabel label="TAKE OFF TIME (TLB)" name="takeofftimetlb">
                <Input
                    name="takeofftimetlb"
                    type="time"
                    placeholder="00:00"
                    onChange={value =>
                        updateForm(formDatas, setFormDatas, 'takeoffTimeTlb', value)
                    }
                    value={formDatas.takeoffTimeTlb}
                    disabled={['editASO'].includes(type)}
                />
              </InputLabel>
              <InputLabel label="LANDING TIME (TLB)" name="landingtimetlb">
                <Input
                    name="landingtimetlb"
                    type="time"
                    placeholder="00:00"
                    onChange={value =>
                        updateForm(formDatas, setFormDatas, 'landingTimeTlb', value)
                    }
                    value={formDatas.landingTimeTlb}
                    disabled={['editASO'].includes(type)}
                />
              </InputLabel>
              <InputLabel label="FLIGHT TIME (TLB)" name="flighttimetlb">
                <Input
                    name="flighttimetlb"
                    type="text"
                    placeholder="00:00"
                    disabled="true"
                    value={
                        formDatas.takeoffTimeTlb && formDatas.landingTimeTlb
                            ? formatMinutesToReadableHourOfDay(
                                formatReadableHourToMinutes(
                                    formDatas.landingTimeTlb
                                ) -
                                formatReadableHourToMinutes(
                                    formDatas.takeoffTimeTlb
                                ),
                            )
                            : '00:00'
                    }
                />
              </InputLabel>
            </span>
                    </div>
                    <div className="delay section">
            <span className="title">
              <span>
                <DelayIcon width={30}/>
                DELAY(S)
              </span>
            </span>
                        <span className="inputs">
              <Delay flightId={editId}/>
            </span>
                    </div>
                    <div className="fuel section">
            <span className="title">
              <span>
                <FuelIcon width={35} fill="#03A9F4"/>
                FUEL
              </span>
            </span>
                        <span className="inputs">
              <InputLabel label="FUEL UPLIFT on departure Airport (L)" name="dueldeparture">
                <Input
                    name="dueldeparture"
                    type="text"
                    placeholder=""
                    onChange={value =>
                        updateForm(
                            formDatas,
                            setFormDatas,
                            'fuelOnDeparture',
                            +value
                        )
                    }
                    value={formDatas.fuelOnDeparture}
                    disabled={['editASO'].includes(type)}
                />
              </InputLabel>
              <InputLabel label="FUEL UPLIFT on arrival airport (L)" name="duelarrival">
                <Input
                    name="duelarrival"
                    type="text"
                    placeholder=""
                    onChange={value =>
                        updateForm(formDatas, setFormDatas, 'fuelOnArrival', +value)
                    }
                    value={formDatas.fuelOnArrival}
                    disabled={['editASO'].includes(type)}
                />
              </InputLabel>
            </span>
                    </div>
                    <div className="status section">
            <span className="title">
              <span>
                <StatusIcon width={30}/>
                STATUS
              </span>
            </span>
                        <span className="inputs">
              <InputLabel label="STATUS" name="flightstatus">
                <Select
                    name="flightstatus"
                    options={flightStatus}
                    itemValue="id"
                    itemLabel="name"
                    className="primary"
                    onChange={value =>
                        updateForm(
                            formDatas,
                            setFormDatas,
                            'flightStatusId',
                            +value
                        )
                    }
                    value={formDatas.flightStatusId}
                    disabled={['editASO', 'editCREW'].includes(type)}
                />
              </InputLabel>
              <InputLabel label="PAX planned" name="seatsPlanned">
                <Input
                    name="seatsPlanned"
                    type="text"
                    placeholder=""
                    defaultStyle="primary"
                    onChange={value =>
                        updateForm(
                            formDatas,
                            setFormDatas,
                            'passengerPlanned',
                            +value
                        )
                    }
                    value={formDatas.passengerPlanned}
                />
              </InputLabel>
              <InputLabel label="PAX" name="seats">
                <Input
                    name="seats"
                    type="text"
                    placeholder=""
                    defaultStyle="primary"
                    onChange={value =>
                        updateForm(
                            formDatas,
                            setFormDatas,
                            'passengerCounter',
                            +value
                        )
                    }
                    value={formDatas.passengerCounter}
                    disabled={['editASO'].includes(type)}
                />
              </InputLabel>
            </span>
                    </div>
                </div>
            )}
            <div className="form-part part-complement">
                <div className="top">
                    <div>
                        <InputLabel label="MISSION" name="mission">
                            <Select
                                name="mission"
                                options={missions}
                                itemValue="id"
                                itemLabel="name"
                                className="primary"
                                onChange={value =>
                                    updateForm(formDatas, setFormDatas, 'missionId', +value)
                                }
                                value={formDatas.missionId}
                                disabled={['editASO', 'editCREW'].includes(type)}
                            />
                        </InputLabel>
                    </div>
                    <div>
                        <InputLabel label="AIRCRAFT" name="aircraft">
                            <Select
                                name="aircraft"
                                options={aircrafts}
                                value={formDatas.aircraftId}
                                itemValue="id"
                                itemLabel="reg"
                                className="primary"
                                onChange={value =>
                                    updateForm(formDatas, setFormDatas, 'aircraftId', +value)
                                }
                                disabled={['editASO', 'editCREW'].includes(type)}
                            />
                        </InputLabel>
                    </div>
                    <div>
                        <InputLabel label="SEATS CAPACITY" name="seatsCapacity">
                            <Input
                                name="seatsCapacity"
                                type="number"
                                placeholder=""
                                defaultStyle="primary"
                                onChange={value =>
                                    updateForm(
                                        formDatas,
                                        setFormDatas,
                                        'passengerCapacity',
                                        value
                                    )
                                }
                                value={formDatas.passengerCapacity}
                                disabled={['editASO', 'editCREW'].includes(type)}
                            />
                        </InputLabel>
                    </div>
                </div>
            </div>
            <div className="m-auto p-20">
                <Button
                    text={`${action()} flight`}
                    disabled={false}
                    defaultStyle={`primary`}
                    onClick={() => validateData()}
                />
            </div>
        </div>
    ) : (
        <div>loading datas</div>
    );
};

export default FlightForm;
