import { useEffect, useState } from 'react';

import TotalFuel from 'components/pages/Stats/Fuel/TotalFuel';
import FuelPlanned from 'components/pages/Stats/Fuel/FuelPlanned';

import './FuelStats.style.scss';

import { getFuelData } from 'services/API/Stats';

const FuelStats = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFuelData()
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <div id="FuelStats" className="statsContainer">
      <div className="statsSection" id="fuelUsed">
        <h3>Total Fuel</h3>
        <TotalFuel data={data} loading={loading} />
      </div>
      <div className="statsSection" id="fuelPlanned">
        <h3>Fuel distribution</h3>
        <FuelPlanned data={data} loading={loading} />
      </div>
    </div>
  );
};

export default FuelStats;
