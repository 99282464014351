import React from 'react';
import PropTypes from 'prop-types';

const AircraftTypeIcon = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 39.801 39.802">
    <g fill={fill} color={color}>
      <path d="M39.801 5.642A5.649 5.649 0 0034.159 0H5.64A5.647 5.647 0 000 5.642v28.519a5.647 5.647 0 005.64 5.641h28.523a5.648 5.648 0 005.642-5.641zm-1.829 28.519a3.817 3.817 0 01-3.813 3.813H5.64a3.816 3.816 0 01-3.812-3.813V5.642A3.816 3.816 0 015.64 1.829h28.523a3.817 3.817 0 013.813 3.813z" />
      <path d="M33.981 25.525a.914.914 0 001.092-.9v-4.727a.909.909 0 00-.577-.849l-11.319-4.5V8.001a3.278 3.278 0 00-6.556 0v6.548l-.169.067-11.149 4.431a.909.909 0 00-.579.849v4.727a.916.916 0 001.093.9l10.8-2.147v3.887l-3 1.334a.915.915 0 00-.543.835v4.727a.915.915 0 001.254.848l5.569-2.227 5.568 2.227a.916.916 0 001.255-.848v-4.727a.916.916 0 00-.543-.835l-3-1.334v-3.887zm-12.09 3.169l3 1.334v2.783l-4.655-1.861a.907.907 0 00-.678 0l-4.656 1.861v-2.783l3-1.334a.917.917 0 00.544-.836v-5.594a.914.914 0 00-.335-.706.9.9 0 00-.759-.19l-10.8 2.147v-2.992l.169-.067 11.15-4.431a.91.91 0 00.578-.849v-7.17a1.45 1.45 0 112.9 0v7.17a.909.909 0 00.577.849l11.319 4.5v2.993l-10.8-2.147a.914.914 0 00-1.091.9v5.594a.914.914 0 00.538.828z" />
    </g>
  </svg>
);

AircraftTypeIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

AircraftTypeIcon.defaultProps = {
  color: '#000',
  fill: '#03a9f4',
  width: 39.801,
  height: 39.802,
};

export default AircraftTypeIcon;
