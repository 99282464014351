import { useEffect, useState } from 'react';

import Table from 'components/generics/Table/ReactTable';

import { getMissions } from 'services/API/Stats';
import { prepareTable } from 'utils/func';
import { MISSIONS_STATS_HEADERS } from 'utils/const';
import { Loader } from '../../../generics/Loader/Loader';

const MissionsStats = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMissions()
      .then(res => {
        setData({ datas: prepareTable(MISSIONS_STATS_HEADERS, res.data.missions), headers: MISSIONS_STATS_HEADERS });
        setLoading(false);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <div id="MissionsStats" className="statsContainer">
      {loading ? (
        <Loader />
      ) : (
        <Table selectedRows={() => {}} datas={data.datas} headers={data.headers} filters={false} selectable={false} name="missionsFilters" />
      )}
    </div>
  );
};

export default MissionsStats;
