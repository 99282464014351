import PropTypes from 'prop-types';

const DateIcon = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 44.446 39.122">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_234" data-name="Rectangle 234" width={44.446} height={39.122} fill="#fff" />
      </clipPath>
    </defs>
    <g id="date" transform="translate(-1250.431 -108.245)">
      <g id="Groupe_157" data-name="Groupe 157" transform="translate(1250.431 108.245)">
        <g id="Groupe_156" data-name="Groupe 156" clipPath="url(#clip-path)">
          <path
            id="Trac\xE9_110"
            data-name="Trac\xE9 110"
            d="M1251.733,142.132h6.51v3.906a1.3,1.3,0,0,0,1.3,1.3h34.028a1.3,1.3,0,0,0,1.3-1.3V112.183a1.3,1.3,0,0,0-1.3-1.3h-6.51v-1.3a1.3,1.3,0,0,0-2.605,0v1.3h-6.6v-1.3a1.3,1.3,0,1,0-2.6,0v1.3h-6.51v-1.3a1.3,1.3,0,1,0-2.6,0v1.3h-6.6a1.3,1.3,0,0,0-1.3,1.3V120c0,9.6-3.987,16.979-7.343,19.833a1.3,1.3,0,0,0,.833,2.3Zm40.54,2.6h-31.425v-2.6h24.914a1.3,1.3,0,0,0,.834-.3,24.016,24.016,0,0,0,5.677-8.383Zm-31.425-31.251h5.3v1.3a1.3,1.3,0,1,0,2.6,0v-1.3h6.51v1.3a1.3,1.3,0,1,0,2.6,0v-1.3h6.6v1.3a1.3,1.3,0,0,0,2.605,0v-1.3h5.208v5.208h-31.425Zm-.021,7.812h31.423c-.294,8.26-3.464,14.759-7,18.231H1254.7c4.005-5.1,5.916-11.747,6.131-18.231Zm0,0"
            transform="translate(-1250.431 -108.247)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);

DateIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

DateIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 44.446,
  height: 39.122,
};

export default DateIcon;
