/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Input from 'components/generics/Input/Input';
import Select from 'components/generics/Select/Select';
import Button from 'components/generics/Button/Button';

import { updateForm } from 'utils/func';

import { add as addUser, edit as editUser, getById as getUserById } from 'services/API/User';
import { get as getAirlines } from 'services/API/Airline';
import { get as getAirports } from 'services/API/Airport';
import { get as getRoles } from 'services/API/Role';

const UserForm = ({ onSubmit, editId, type }) => {
  const DEFAULT_VALUES = {
    firstname: '',
    lastname: '',
    password: '',
    email: '',
    phone: '',
    userRoleId: 0,
    airlineId: null,
    airportId: null,
  };

  const [formDatas, setFormDatas] = useState(DEFAULT_VALUES);
  const [airlines, setAirlines] = useState([]);
  const [airports, setAirports] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getAirports()
      .then(res => setAirports([...[{ name: 'Select an Airport', id: null }], ...res.data]))
      .catch(e => toast.error(e.message));

    getAirlines()
      .then(resAirline => setAirlines([...[{ civilCode: 'Select an Airline', id: null }], ...resAirline.data]))
      .catch(e => toast.error(e.message));

    getRoles()
      .then(resRole => setRoles([...[{ name: 'Select Role', id: 0 }], ...resRole.data]))
      .catch(e => toast.error(e.message));

    if (type === 'edit') {
      getUserById(editId)
        .then(resUser => {
          console.log(resUser.data.airlineId);
          setFormDatas({
            id: resUser.data.id,
            firstname: resUser.data.firstname,
            lastname: resUser.data.lastname,
            email: resUser.data.email,
            phone: resUser.data.phone,
            password: resUser.data.password,
            userRoleId: resUser.data.userRoleId,
            airlineId: resUser.data.airlineId,
            airportId: resUser.data.airportId,
          });
        })
        .catch(e => toast.error(e.message));
    }
  }, []);

  const validateData = () => {
    if (type === 'edit') {
      editUser(formDatas)
        .then(e => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch(err => toast.error((err.response.data.message || []).join('')));
    } else {
      addUser(formDatas)
        .then(e => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch(err => toast.error((err.response.data.message || []).join('')));
    }
  };

  useEffect(() => {
    if (formDatas.userRoleId === 5 || formDatas.userRoleId === 8) {
      updateForm(formDatas, setFormDatas, 'airportId', null);
    } else if (formDatas.userRoleId === 6) {
      updateForm(formDatas, setFormDatas, 'airlineId', null);
    } else {
      setFormDatas({ ...formDatas, ...{ airportId: null, airlineId: null } });
    }
  }, [formDatas.userRoleId]);

  const action = () => (type === 'edit' ? 'Edit' : 'Add');

  return (
    <div className="modal__content">
      <h2>{action()} user</h2>
      <div className="form-part">
        <InputLabel label="Firstname" name="firstname">
          <Input name="firstname" type="string" onChange={value => updateForm(formDatas, setFormDatas, 'firstname', value)} value={formDatas.firstname} />
        </InputLabel>
        <InputLabel label="Lastname" name="lastname">
          <Input name="lastname" type="string" onChange={value => updateForm(formDatas, setFormDatas, 'lastname', value)} value={formDatas.lastname} />
        </InputLabel>
        <InputLabel label="Password" name="password">
          <Input name="password" type="password" onChange={value => updateForm(formDatas, setFormDatas, 'password', value)} value={formDatas.password} />
        </InputLabel>
        <InputLabel label="E-mail" name="email">
          <Input name="email" type="string" onChange={value => updateForm(formDatas, setFormDatas, 'email', value)} value={formDatas.email} />
        </InputLabel>
        <InputLabel label="Phone" name="phone">
          <Input name="phone" type="string" onChange={value => updateForm(formDatas, setFormDatas, 'phone', value)} value={formDatas.phone} />
        </InputLabel>
        <InputLabel label="Role" name="role">
          <Select
            name="role"
            options={roles}
            value={formDatas.userRoleId}
            itemValue="id"
            itemLabel="name"
            onChange={value => updateForm(formDatas, setFormDatas, 'userRoleId', +value)}
          />
        </InputLabel>
        {(formDatas.userRoleId === 5 || formDatas.userRoleId === 8) && (
          <InputLabel label="Airline" name="airline">
            <Select
              name="airline"
              options={airlines}
              value={formDatas.airlineId}
              itemValue="id"
              itemLabel="name"
              onChange={value => updateForm(formDatas, setFormDatas, 'airlineId', +value)}
            />
          </InputLabel>
        )}
        {formDatas.userRoleId === 6 && (
          <InputLabel label="Airport" name="airport">
            <Select
              name="airport"
              options={airports}
              value={formDatas.airportId}
              itemValue="id"
              itemLabel="civilCode"
              onChange={value => updateForm(formDatas, setFormDatas, 'airportId', +value)}
            />
          </InputLabel>
        )}
      </div>
      <Button text={`${action()} user`} disabled={false} defaultStyle={`primary`} onClick={e => validateData(e)} />
    </div>
  );
};

export default UserForm;
