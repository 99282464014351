import API from 'services/API';

export const get = () => API.get('/flightTime');

export const getById = id => API.get(`/flightTime/${id}`);

export const add = route => API.post('/flightTime', route);

export const edit = route => API.patch(`/flightTime/${route.id}`, route);

export const bulkDelete = route => API.delete('/flightTime/byBulk', { data: route });
