import API from 'services/API';

export const get = () => API.get('/aircraftType');

export const getById = id => API.get(`/aircraftType/${id}`);

export const add = aircraftType => API.post('/aircraftType', aircraftType);

export const edit = aircraftType => API.patch(`/aircraftType/${aircraftType.id}`, aircraftType);

export const bulkDelete = aircraftType => API.delete('/aircraftType/byBulk', { data: aircraftType });
