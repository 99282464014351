import Button from 'components/generics/Button/Button';
import RoleGuard from 'components/generics/RoleGuard/RoleGuard';

const FlightButton = ({ allowedRoles, text, disabled, defaultStyle, onClick }) => (
  <RoleGuard allowedRoles={allowedRoles}>
    <Button text={text} disabled={disabled} defaultStyle={defaultStyle} onClick={onClick} />
  </RoleGuard>
);

export default FlightButton;
