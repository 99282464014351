import React from 'react';
import PropTypes from 'prop-types';

const InputLabel = ({ name, defaultStyle, label, children }) => (
  <label className={defaultStyle} htmlFor={name}>
    {label}
    {children}
  </label>
);

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  name: PropTypes.string,
  defaultStyle: PropTypes.string,
};

InputLabel.defaultProps = {
  defaultStyle: '',
  name: '',
};

export default InputLabel;
