/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import './Flights.style.scss';

import Table from 'components/generics/Table/ReactTable';
import Modal from 'components/generics/Modal/Modal';
import FlightForm from 'components/pages/Flights/FlightForm';
import ConfirmDelete from 'components/generics/ConfirmDelete/ConfirmDelete';
import FlightButton from 'components/pages/Flights/FlightButton';

import useAuth from 'providers/Auth/useAuth';
import { prepareTable, getFlightHeaderByRole } from 'utils/func';

import { getCancelled as getFlights, bulkDelete as deleteFlights } from 'services/API/Flight';

const Flights = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [formType, setFormType] = useState(null);
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [data, setData] = useState({ datas: [], headers: [] });
  const [editId, setEditId] = useState(null);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    getFlights()
      .then(res => {
        if (user.roleName !== 'CREW') {
          setData({
            datas: prepareTable(getFlightHeaderByRole(user.roleName), res.data),
            headers: getFlightHeaderByRole(user.roleName),
          });
        } else {
          setData({
            datas: prepareTable(
              getFlightHeaderByRole(user.roleName),
              res.data.filter(item => item.aircraft.airline.name === user.airlineName),
            ),
            headers: getFlightHeaderByRole(user.roleName),
          });
        }
      })
      .catch(e => console.error(e));
  };

  const onDelete = () => {
    if (selectedFlights.length > 0) {
      deleteFlights({ ids: selectedFlights.map(v => +v.original.id) })
        .then(e => {
          getDatas();
          setConfirmDeletePopup(false);
        })
        .catch(err => console.error(err));
    }
  };

  useEffect(() => {
    if (selectedFlights.length === 1) {
      setEditId(selectedFlights[0].original.id);
    } else {
      setEditId(null);
    }
  }, [selectedFlights]);

  const onSubmitForm = () => {
    setModalVisibility(false);
    getDatas();
  };

  return (
    <div className="flights" id="flights-all">
      <div className="crud__header">
        <div>
          <h1>Flight Cancelled</h1>
          <h2>{user?.datasource.replace('_', ' ')}</h2>
        </div>
        <FlightButton
          allowedRoles={['Admin', 'CPSAdmin', 'CPSUser', 'DISPATCH']}
          text="Edit"
          disabled={selectedFlights.length !== 1}
          defaultStyle="primary"
          onClick={() => {
            setFormType('edit');
            setModalVisibility(true);
          }}
        />
        <FlightButton
          allowedRoles={['ASO']}
          text="Edit"
          disabled={selectedFlights.length !== 1}
          defaultStyle="primary"
          onClick={() => {
            setFormType('editASO');
            setModalVisibility(true);
          }}
        />
        <FlightButton
          allowedRoles={['CREW']}
          text="Edit"
          disabled={selectedFlights.length !== 1}
          defaultStyle="primary"
          onClick={() => {
            setFormType('editCREW');
            setModalVisibility(true);
          }}
        />
        <FlightButton
          allowedRoles={['Admin', 'CPSAdmin', 'CPSUser', 'DISPATCH']}
          text="Delete"
          disabled={selectedFlights.length === 0}
          defaultStyle={`primary${selectedFlights.length === 0 ? ' disabled' : ''}`}
          onClick={() => setConfirmDeletePopup(true)}
        />
      </div>
      <Table selectedRows={d => setSelectedFlights(d)} datas={data.datas} headers={data.headers} name="canceledFlightFilters" />
      {modalVisibility && (
        <Modal onClose={() => setModalVisibility(false)}>
          <FlightForm onSubmit={onSubmitForm} editId={editId} type={formType} />
        </Modal>
      )}
      {confirmDeletePopup && (
        <ConfirmDelete onClose={() => setConfirmDeletePopup(false)} list={selectedFlights} onConfirm={onDelete} formatList={data => `Item : ${data.name}`} />
      )}
    </div>
  );
};

export default Flights;
