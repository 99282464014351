import { Switch, Route, Redirect } from 'react-router-dom';

import LoginScreen from 'components/pages/Login/Login';
import DashboardScreen from 'components/pages/Dashboard';
import FlightsScreen from 'components/pages/Flights/Flights';
import FlightsCancelledScreen from 'components/pages/Flights/FlightsCancelled';
import AllFlightsScreen from 'components/pages/Flights/AllFlights';
import AnomaliesFlightsScreen from 'components/pages/Flights/AnomaliesFlights';
import StatsScreen from 'components/pages/Stats/Stats';
import ParametersScreen from 'components/pages/parameters/Parameters';
import AircraftsScreen from 'components/pages/parameters/Aircrafts/Aircrafts';
import AirportScreen from 'components/pages/parameters/Airports/Airports';
import UsersScreen from 'components/pages/parameters/Users/Users';
import CompaniesScreen from 'components/pages/parameters/Companies/Companies';
import RoutesScreen from 'components/pages/parameters/Routes/Routes';
import AircraftTypesScreen from 'components/pages/parameters/AircraftTypes/AircraftTypes';
import StagesScreen from 'components/pages/parameters/Stages/Stages';
import MissionsScreen from 'components/pages/parameters/Missions/Missions';
import DelayTypesScreen from 'components/pages/parameters/DelayTypes/DelayTypes';
import ForgotPassword from 'components/pages/ForgotPassword/ForgotPassword';
import ResetPassword from 'components/pages/ResetPassword/ResetPassword';
import DatabasesScreen from 'components/pages/Databases/Databases';

import ProtectedRoute from 'providers/Auth/ProtectedRoute';
import ContextDatabaseProvider from 'components/pages/Databases/ContextDatabase';
import Datasource from 'components/pages/Datasources/Datasource';

const Routes = () => (
  <Switch>
    <Route path="/login">
      <LoginScreen />
    </Route>
    <Route path="/datasource">
      <Datasource />
    </Route>

    <Route path="/forgotPassword">
      <ForgotPassword />
    </Route>

    <Route path="/resetPassword">
      <ResetPassword />
    </Route>

    <Route path="/dashboard">
      <ProtectedRoute>
        <DashboardScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/flights/cancel">
      <ProtectedRoute>
        <FlightsCancelledScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/flights/anomalies/:anomalies">
      <ProtectedRoute>
        <AnomaliesFlightsScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/flights/all">
      <ProtectedRoute>
        <AllFlightsScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/flights">
      <ProtectedRoute>
        <FlightsScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/stats">
      <ProtectedRoute>
        <StatsScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/parameters/aircrafts">
      <ProtectedRoute>
        <AircraftsScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/parameters/airports">
      <ProtectedRoute>
        <AirportScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/parameters/users">
      <ProtectedRoute>
        <UsersScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/parameters/airlines">
      <ProtectedRoute>
        <CompaniesScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/parameters/routes">
      <ProtectedRoute>
        <RoutesScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/parameters/aircrafttypes">
      <ProtectedRoute>
        <AircraftTypesScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/parameters/stages">
      <ProtectedRoute>
        <StagesScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/parameters/missions">
      <ProtectedRoute>
        <MissionsScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/parameters/delaytypes">
      <ProtectedRoute>
        <DelayTypesScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/parameters">
      <ProtectedRoute>
        <ParametersScreen />
      </ProtectedRoute>
    </Route>

    <Route path="/databases">
      <ProtectedRoute>
        <ContextDatabaseProvider>
          <DatabasesScreen />
        </ContextDatabaseProvider>
      </ProtectedRoute>
    </Route>

    <Route path="/">
      <Redirect to="/login" />
    </Route>
  </Switch>
);

export default Routes;
