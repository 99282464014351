import { createContext, useState } from 'react';
import { DATABASES_HEADERS } from 'utils/const';
import { prepareTable } from 'utils/func';

export const ContextDatabase = createContext(null);

const initialData = {
  datas: prepareTable(DATABASES_HEADERS, [
    { id: 1, name: 'Dakar 90', year: 1990 },
    { id: 2, name: 'Dakar 98', year: 1998 },
    { id: 3, name: 'Dakar 20', year: 2020 },
  ]),
  headers: DATABASES_HEADERS,
};

function ContextDatabaseProvider({ children }) {
  const [data, setData] = useState(initialData);

  return <ContextDatabase.Provider value={{ data: data, setData: setData }}>{children}</ContextDatabase.Provider>;
}

export default ContextDatabaseProvider;
