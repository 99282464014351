import React from 'react';

import './DateStamp.style.scss';
import { shortMonth, shortDayName } from 'utils/const';

const DateStamp = ({ date }) => (
  <div className="dateStamp">
    <div className="day">{shortDayName[date.getDay()]}</div>
    <div className="date">{date.getDate()}</div>
    <div className="month">{shortMonth[date.getMonth()]}</div>
  </div>
);

export default DateStamp;
