import { useLocation } from 'react-router-dom';
import { v1 } from 'uuid';

import MenuItem from 'components/generics/Menu/MenuItem';

import { ItemsNav } from 'utils/const';
import { isAuthorised } from 'utils/func';

import logo from 'assets/images/logo.png';
import './menu.scss';

const Menu = ({ navState, items, user }) => {
  const location = useLocation();
  return (
    <nav className={navState ? 'expand' : undefined}>
      <img src={logo} alt="logo CPS" />
      <span className={'items'}>
        {ItemsNav?.filter(item => isAuthorised(item.allowedRoles, user.roleName)).map(r => (
          <MenuItem
            active={location.pathname.split('/').indexOf(r.path.slice(1, r.path.length)) !== -1}
            path={r.path}
            expanded={navState}
            icon={r.icon}
            title={r.title}
            subItems={r.subItems}
            key={v1()}
          />
        ))}
      </span>
    </nav>
  );
};

export default Menu;
