import { useState, useEffect } from 'react';
import { v1 } from 'uuid';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Tag from 'components/generics/Tag/Tag';
import Input from 'components/generics/Input/Input';
import Button from 'components/generics/Button/Button';
import useAuth from 'providers/Auth/useAuth';

import { get as getDelayTypes, add as addDelayTypes, del as deleteDelayTypes } from 'services/API/DelayType';

import './DelayTypes.style.scss';

const DelayTypes = () => {
  const [inputValue, setInputValue] = useState('');
  const [delayTypes, setDelayTypes] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    getDelayTypes()
      .then(res => {
        setDelayTypes(res.data);
      })
      .catch(e => console.error(e));
  };

  const onDelete = id => {
    deleteDelayTypes(id)
      .then(res => getDatas())
      .catch(err => console.log(err));
  };

  const onAdd = name => {
    addDelayTypes({ name: inputValue })
      .then(res => {
        getDatas();
        setInputValue('');
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="params crud" id="delayTypes">
      <div className="crud__header">
        <div>
          <h1>DelayTypes</h1>
          <h2>{user?.datasource.replace('_', ' ')}</h2>
        </div>
      </div>
      <div className="content">
        <InputLabel label="CREATE A DELAYTYPE" name="delayType">
          <Input name="delayType" type="text" placeholder="Enter a name" onChange={value => setInputValue(value)} value={inputValue} />
        </InputLabel>
        <Button text={'add'} disabled={inputValue === ''} defaultStyle={`primary`} onClick={e => onAdd()} />
        <div className="tag__container">
          {delayTypes.map(v => (
            <Tag key={v1()} title={v.name} onDelete={() => onDelete(v.id)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DelayTypes;
