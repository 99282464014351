// TODO : redirection, wrapper
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from 'components/generics/Button/Button';

import './quickViewItem.scss';

const QuickViewItem = ({ title, value, unit, path, icon }) => {
  const history = useHistory();

  return (
    <div className="QuickViewItem">
      <div className="content">
        <div className="data">
          <span className="title">{title}</span>
          <span className="value">{value}</span>
          <span className="unit">{unit}</span>
          <Button defaultStyle="secondary" text="See more" onClick={() => history.push(path)} />
        </div>
        <div className="icon">{icon}</div>
      </div>
    </div>
  );
};

QuickViewItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

export default QuickViewItem;
