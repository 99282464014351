import React, { useEffect, useState } from 'react';
import Button from 'components/generics/Button/Button';
import Modal from 'components/generics/Modal/Modal';
import Table from 'components/generics/Table/ReactTable';

import FormDatabase from './FormDatabase';
import { DATABASES_HEADERS } from 'utils/const';
import { deleteDatabase, getDatabases } from 'services/API/Database';

function Databases() {
  const [formType, setFormtype] = useState(null);
  const [checkedDb, setCheckedDb] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState({});
  const [modalVisibility, setModalVisibility] = useState(false);
  const [valueEdit, setValueEdit] = useState();
  const [databases, setDatabases] = useState([]);

  const openModalAdd = e => {
    setFormtype('add');
    setModalVisibility(true);
  };

  const deleteDb = () => {
    if (checkedDb && checkedDb.length > 0) {
      checkedDb.forEach(c => {
        deleteDatabase(c?.values?.db)
          .then(result => {
            getDb();
          })
          .catch(error => {
            console.error('Error while deleting database :', error);
          });
      });
    }
  };

  const openModalEdit = e => {
    setFormtype('edit');
    setValueEdit(e);
    setModalVisibility(true);
  };

  const onSubmitForm = () => {
    setModalVisibility(false);
    const itv = setInterval(() => {
      clearInterval(itv);
    }, 3000);
    getDb();
  };

  useEffect(() => {
    let newDb = [...databases];
    newDb.map(d => (d.years === selectedDatabase.years ? (d.isActive = 'Yes') : (d.isActive = 'No')));
    setDatabases([...newDb]);
  }, [selectedDatabase]);

  useEffect(() => {
    getDb();
  }, []);

  const getDb = () => {
    getDatabases()
      .then(result => {
        setDatabases(result?.data?.filter(db => db?.db != 'cps_auth'));
      })
      .catch(error => {
        console.error('Error during getting databases:', error);
      });
  };

  return (
    <div className="databases crud">
      <div className="crud__header">
        <div>
          <h1>Databases</h1>
        </div>
        <Button text="Add a database" defaultStyle="primary" onClick={openModalAdd} />
        <Button text="Delete a database" defaultStyle="primary" onClick={() => deleteDb()} />
      </div>
      <Table selectedRows={d => setCheckedDb(d)} datas={databases} headers={DATABASES_HEADERS} editable={true} onEdit={openModalEdit} name="databasesFilters" />
      {modalVisibility && (
        <Modal onClose={() => setModalVisibility(false)}>
          <FormDatabase onSubmit={onSubmitForm} value={valueEdit} type={formType} />
        </Modal>
      )}
    </div>
  );
}

export default Databases;
