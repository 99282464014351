import PropTypes from 'prop-types';

const StatusIcon = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 43.462 43.463">
    <g color={color} fill={fill} id="status" transform="translate(-1252.716 -225.022)">
      <g id="Groupe_193" transform="translate(1252.716 225.022)">
        <path
          id="Trac\xE9_180"
          d="M1296.177,259.544V258.52a8.767,8.767,0,0,0-8.757-8.757h-7.509l-.067-.045-7.666-5.111h-7.936l5.156,5.155h-9.871l-2.578-5.155h-4.234v6.179a8.767,8.767,0,0,0,8.757,8.757h7.451l-3.866,7.733h7.322l5.8-7.733Zm-26.993-12.889h2.375l4.663,3.109h-3.929Zm-7.712,10.842a6.717,6.717,0,0,1-6.711-6.71v-4.132h.923l2.577,5.155h29.16a6.733,6.733,0,0,1,6.576,5.368l.064.318h-14.345l1.934-2.578h-10.413l-1.29,2.578Zm9.884,7.732h-2.986l4.131-8.263h5.054Z"
          transform="translate(-1252.716 -223.814)"
          fill="#03a9f4"
        />
        <path
          id="Trac\xE9_181"
          d="M1273.693,225.022a8.757,8.757,0,1,0,8.757,8.757A8.767,8.767,0,0,0,1273.693,225.022Zm0,15.466a6.71,6.71,0,1,1,6.711-6.71A6.717,6.717,0,0,1,1273.693,240.488Z"
          transform="translate(-1251.962 -225.022)"
          fill="#03a9f4"
        />
      </g>
      <path
        id="Trac\xE9_182"
        d="M1272.99,236.627l-3.9-3.206,1.012-1.231,2.724,2.239,3.865-4.308,1.186,1.064Z"
        transform="translate(1.01 0.314)"
        fill="#03a9f4"
      />
    </g>
  </svg>
);

StatusIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

StatusIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 43.462,
  height: 43.463,
};

export default StatusIcon;
