import Pie from 'components/generics/Charts/Pie';
import { Loader } from '../../../generics/Loader/Loader';

const FuelPlanned = ({ data, loading }) =>
  !loading ? (
    <>
      <div className="highlight_value">
        <div>
          <span>{data?.totalFuelAvailable.toLocaleString()}L</span>
          <span>planned</span>
        </div>
        <div>
          <span>{data?.totalFuelUsed.toLocaleString()}L</span>
          <span>burnt</span>
        </div>
      </div>
      <div className="graphContainer">
        <Pie
          data={[
            {
              id: 'fuelPlanned',
              value: data.totalFuelAvailable,
              label: 'Fuel planned',
            },
            {
              id: 'fuelUsed',
              value: data.totalFuelUsed,
              label: 'Fuel burnt',
            },
          ]}
          colors={['#03A9F4', '#eb5367']}
          enableArcLinkLabel={false}
        />
      </div>
    </>
  ) : (
    <Loader />
  );

export default FuelPlanned;
