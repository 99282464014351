import API from 'services/API';

export const get = () => API.get('/stage');

export const getById = id => API.get(`/stage/${id}`);

export const add = stage => API.post('/stage', stage);

export const edit = (stage, id) => API.put(`/stage/${id}`, stage);

export const bulkDelete = stage => API.delete('/stage/byBulk', { data: stage });
