import Bar from 'components/generics/Charts/Bar';

const DelaysTypeContainer = ({ data, loading }) =>
  !loading ? (
    <>
      <div className="graphContainer" style={{ marginTop: 20 }}>
        <Bar data={data.delays} keys={['counter']} indexBy={'delayTypeName'} />
      </div>
    </>
  ) : (
    <>loading</>
  );

export default DelaysTypeContainer;
