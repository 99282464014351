import Tab from 'components/generics/Tabs/Tab';
import './tabs.scss';
import RoleGuard from '../RoleGuard/RoleGuard';
import { UserRole } from '../../../utils/interface/user';

const Tabs = ({ url }: { url: string }) => {
  return (
    <div className="TabsContainer">
      <Tab url={url} title="Delays" path="delays" />
      <Tab url={url} title="Seats optimization" path="seats" />
      <Tab url={url} title="Fuel report" path="fuel" />
      <Tab url={url} title="Missions" path="missions" />
      <Tab url={url} title="Aircraft flight times" path="flightTime" />
      <RoleGuard allowedRoles={[UserRole.CPS_ADMIN]}>
        <Tab url={url} title="Aircraft Cost" path="aircraftCost" />
      </RoleGuard>
    </div>
  );
};

export default Tabs;
