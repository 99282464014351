import { Redirect } from 'react-router-dom';

import { isAuthorised } from 'utils/func';

import useAuth from 'providers/Auth/useAuth';

import LoginForm from 'components/pages/Login/LoginForm';

import './Login.style.scss';
import logo from 'assets/images/cps_logo.png';

const Login = () => {
  const { user } = useAuth();

  const userHasDatasource = () => {
    return user?.datasource != '' && user?.datasource != null ? true : false;
  };

  return user ? (
    // If user is "truthy" check the role of the user
    isAuthorised(['Admin', 'CPSAdmin', 'CPSUser', 'ASO', 'Authority'], user.roleName) ? (
      userHasDatasource() ? (
        // if the user's role is either Admin, CPSAdmin, CPSUser, ASO, Authority, redirect to the dashboard
        <Redirect to="/dashboard" />
      ) : (
        // No database selected for the current user

        <Redirect to="/datasource" />
      )
    ) : // if the user's role is DBA redirect to database
    isAuthorised(['DBA'], user.roleName) ? (
      <Redirect to="/databases" />
    ) : userHasDatasource() ? (
      // if the user is neither of the above roles redirect to flights
      <Redirect to="/flights" />
    ) : (
      // No database selected for the current user
      <Redirect to="/datasource" />
    )
  ) : (
    // If the user is "falsy" display the LoginForm
    <div className="Login">
      <div className="form">
        <div className="logoContainer">
          <img className="logo m-auto d-block" src={logo} alt="logo cps" />
        </div>
        <div className="container">
          <h1>Sign in</h1>
          <LoginForm />
          <p>Version : {process.env.REACT_APP_VERSION}</p>
        </div>
      </div>
      <div className="img" />
    </div>
  );
};

export default Login;
