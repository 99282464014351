import React from 'react';
import PropTypes from 'prop-types';

const DashboardIcon = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 27.874 27.874">
    <g id="dashboard" transform="translate(0 0)">
      <path
        d="M659.581,44.776h-6.4a3.227,3.227,0,0,1-3.223-3.224v-6.4a3.227,3.227,0,0,1,3.223-3.224h6.4a3.227,3.227,0,0,1,3.223,3.224v6.4A3.227,3.227,0,0,1,659.581,44.776Zm-6.4-10.67a1.047,1.047,0,0,0-1.046,1.046v6.4a1.047,1.047,0,0,0,1.046,1.046h6.4a1.047,1.047,0,0,0,1.046-1.046v-6.4a1.047,1.047,0,0,0-1.046-1.046Z"
        transform="translate(-649.956 -31.928)"
        fill={fill}
        color={color}
      />
      <path
        d="M702.2,44.776h-6.315a3.27,3.27,0,0,1-3.266-3.267V35.195a3.27,3.27,0,0,1,3.266-3.267H702.2a3.27,3.27,0,0,1,3.267,3.267V41.51A3.27,3.27,0,0,1,702.2,44.776Zm-6.315-10.67a1.09,1.09,0,0,0-1.089,1.089V41.51a1.09,1.09,0,0,0,1.089,1.089H702.2a1.09,1.09,0,0,0,1.089-1.089V35.195a1.09,1.09,0,0,0-1.089-1.089Z"
        transform="translate(-677.59 -31.928)"
        fill={fill}
        color={color}
      />
      <path
        d="M659.581,87.435h-6.4a3.227,3.227,0,0,1-3.223-3.223v-6.4a3.227,3.227,0,0,1,3.223-3.223h6.4A3.227,3.227,0,0,1,662.8,77.81v6.4A3.227,3.227,0,0,1,659.581,87.435Zm-6.4-10.67a1.047,1.047,0,0,0-1.046,1.046v6.4a1.047,1.047,0,0,0,1.046,1.046h6.4a1.047,1.047,0,0,0,1.046-1.046v-6.4a1.047,1.047,0,0,0-1.046-1.046Z"
        transform="translate(-649.956 -59.561)"
        fill={fill}
        color={color}
      />
      <path
        d="M702.2,87.435h-6.315a3.27,3.27,0,0,1-3.266-3.266V77.854a3.27,3.27,0,0,1,3.266-3.267H702.2a3.27,3.27,0,0,1,3.267,3.267v6.315A3.27,3.27,0,0,1,702.2,87.435Zm-6.315-10.67a1.09,1.09,0,0,0-1.089,1.089v6.315a1.09,1.09,0,0,0,1.089,1.089H702.2a1.09,1.09,0,0,0,1.089-1.089V77.854a1.09,1.09,0,0,0-1.089-1.089Z"
        transform="translate(-677.59 -59.561)"
        fill={fill}
        color={color}
      />
    </g>
  </svg>
);

DashboardIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

DashboardIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 27.874,
  height: 27.874,
};

export default DashboardIcon;
