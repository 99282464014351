/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Input from 'components/generics/Input/Input';
import Select from 'components/generics/Select/Select';
import Button from 'components/generics/Button/Button';

import { updateForm } from 'utils/func';

import { getById as getRouteById, add as addRoute, edit as editRoute } from 'services/API/Route';
import { get as getAirports } from 'services/API/Airport';

const FormRoute = ({ onSubmit, editId, type }) => {
  const DEFAULT_VALUES = {
    departureAirportId: 0,
    arrivalAirportId: 0,
    distance: '',
  };

  const [formDatas, setFormDatas] = useState(DEFAULT_VALUES);
  const [airports, setAirports] = useState([]);

  useEffect(() => {
    getAirports()
      .then(res => setAirports([...[{ icaoCode: 'Select an airport', id: 0 }], ...res.data]))
      .catch(e => console.log(e));

    if (type === 'edit') {
      getRouteById(editId)
        .then(res => {
          setFormDatas({
            id: res.data.id,
            departureAirportId: res.data.departureAirportId,
            arrivalAirportId: res.data.arrivalAirportId,
            distance: res.data.distance,
          });
        })
        .catch(e => console.error(e));
    }
  }, []);

  const validateData = () => {
    if (type === 'edit') {
      editRoute(formDatas)
        .then(e => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch(err => console.log(err));
    } else {
      addRoute(formDatas)
        .then(e => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch(err => console.log(err));
    }
  };

  const action = () => (type === 'edit' ? 'Edit' : 'Add a');

  return (
    <div className="modal__content">
      <h2>{action()} route</h2>
      <div className="form-part">
        <InputLabel label="From(ICAO)" name="departureAirport">
          <Select
            name="departureAirport"
            options={airports}
            itemValue="id"
            itemLabel="icaoCode"
            onChange={value => updateForm(formDatas, setFormDatas, 'departureAirportId', +value)}
            value={formDatas.departureAirportId}
          />
        </InputLabel>

        <InputLabel label="To(ICAO)" name="arrivalAirport">
          <Select
            name="arrivalAirport"
            options={airports}
            itemValue="id"
            itemLabel="icaoCode"
            onChange={value => updateForm(formDatas, setFormDatas, 'arrivalAirportId', +value)}
            value={formDatas.arrivalAirportId}
          />
        </InputLabel>

        <InputLabel label="Distance" name="distance">
          <Input name="distance" type="number" onChange={value => updateForm(formDatas, setFormDatas, 'distance', +value)} value={formDatas.distance} />
        </InputLabel>
      </div>
      <Button text={`${action()} route`} disabled={false} defaultStyle={`primary`} onClick={e => validateData(e)} />
    </div>
  );
};

export default FormRoute;
