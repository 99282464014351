import React from 'react';
import PropTypes from 'prop-types';

import './button.scss';

const Button = ({ text, type, textColor, backgroundColor, onClick, disabled, defaultStyle, icon }) => (
  <button
    style={{ color: textColor, backgroundColor }}
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={`${defaultStyle}${disabled ? ' disabled' : ''}`}>
    <>
      {icon && icon}
      {text}
    </>
  </button>
);

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  defaultStyle: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.oneOf([null])]),
};

Button.defaultProps = {
  textColor: '',
  onClick: () => {},
  type: 'button',
  backgroundColor: '',
  disabled: false,
  defaultStyle: '',
  icon: null,
};

export default Button;
