import PropTypes from 'prop-types';

const LandingIcon = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 54.3 47.514">
    <g color={color} fill={fill} id="landing" transform="translate(-1628.419 -114.373)">
      <path
        id="Trac\xE9_124"
        data-name="Trac\xE9 124"
        d="M1665.75,131.344a1.134,1.134,0,0,1-1.03-.659l-6.391-13.855-2.668.667,1.034,10.34a1.131,1.131,0,0,1-2.251.224l-1.131-11.313a1.133,1.133,0,0,1,.851-1.21l4.525-1.132a1.135,1.135,0,0,1,1.3.623l6.787,14.706a1.131,1.131,0,0,1-.554,1.5A1.094,1.094,0,0,1,1665.75,131.344Z"
        fill="#03a9f4"
      />
      <path
        id="Trac\xE9_125"
        data-name="Trac\xE9 125"
        d="M1672.537,139.262a1.131,1.131,0,0,1,0-2.262c3.485,0,4.523-.514,4.72-.634a1.131,1.131,0,0,1,2.016.971C1678.875,138.615,1676.608,139.262,1672.537,139.262Z"
        fill="#03a9f4"
      />
      <path
        id="Trac\xE9_126"
        data-name="Trac\xE9 126"
        d="M1658.962,157.362a1.081,1.081,0,0,1-.224-.022l-5.656-1.131a1.131,1.131,0,0,1-.9-1.243l2.262-19.231a1.134,1.134,0,0,1,1.125-1c3.346,0,10.237,2.222,10.529,2.317a1.132,1.132,0,0,1,.711,1.473l-6.788,18.1A1.131,1.131,0,0,1,1658.962,157.362Zm-4.412-3.167,3.691.737,6.029-16.075a51.041,51.041,0,0,0-7.7-1.8Z"
        fill="#03a9f4"
      />
      <path
        id="Trac\xE9_127"
        data-name="Trac\xE9 127"
        d="M1677.538,145.627c-2.81,0-6.969-.611-12.992-1.858a1.131,1.131,0,1,1,.46-2.215c8.97,1.86,14.136,2.306,15.344,1.322a.232.232,0,0,0,.106-.22c0-5.509-8.894-9.471-17.217-11.34a182.567,182.567,0,0,0-20.23-3.371,1.135,1.135,0,0,1-.9-.622l-4.12-8.238-2.787.7v7.037a1.126,1.126,0,0,1-.48.923,1.11,1.11,0,0,1-1.031.14c-1.037-.369-2.489-.566-2.921-.265-.025.018-.093.068-.093.333.027.28.744,1.439,3.706,3.346a1.133,1.133,0,0,1,.459,1.31L1633.382,137h3.387l1.2-2.4a1.132,1.132,0,0,1,1.5-.516,74.13,74.13,0,0,0,15.215,5.21,1.131,1.131,0,0,1-.5,2.206,75.771,75.771,0,0,1-14.685-4.9l-1.023,2.046a1.131,1.131,0,0,1-1.011.626h-5.657a1.131,1.131,0,0,1-1.072-1.491l1.688-5.061c-4.009-2.744-4.009-4.249-4.009-4.76a2.516,2.516,0,0,1,1.054-2.181,4.726,4.726,0,0,1,3.471-.421V118.9a1.13,1.13,0,0,1,.857-1.1l4.525-1.131a1.128,1.128,0,0,1,1.285.591l4.249,8.5a182.619,182.619,0,0,1,19.892,3.35c4.437,1,18.967,4.933,18.967,13.546a2.478,2.478,0,0,1-.939,1.975C1680.968,145.292,1679.624,145.627,1677.538,145.627Z"
        fill="#03a9f4"
      />
      <path
        id="Trac\xE9_128"
        data-name="Trac\xE9 128"
        d="M1654.437,161.887H1629.55a1.131,1.131,0,1,1,0-2.262h24.887a1.131,1.131,0,1,1,0,2.262Z"
        fill="#03a9f4"
      />
    </g>
  </svg>
);

LandingIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

LandingIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 54.3,
  height: 47.514,
};

export default LandingIcon;
