import { isAuthorised } from 'utils/func';

import useAuth from 'providers/Auth/useAuth';
import { UserRole } from '../../../utils/interface/user';

const RoleGuard = ({ allowedRoles, children }: { allowedRoles: UserRole[] | string[]; children: any }) => {
  const { user } = useAuth();

  return isAuthorised(allowedRoles, user.roleName) && children;
};

export default RoleGuard;
