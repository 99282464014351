import React from 'react';
import PropTypes from 'prop-types';

const FlightIcon = ({ color, fill, width, height }) => (
  <svg id="flight" width={width} height={height} viewBox="0 0 34.99 20.22">
    <path
      fill={fill}
      color={color}
      id="Tracé_53"
      data-name="Tracé 53"
      d="M962.748,54.208a2.782,2.782,0,0,0-1.512-1.533,11.251,11.251,0,0,0-8.092-.328l-2.776.938-7.922-3.642a1.1,1.1,0,0,0-.805-.042l-4.73,1.6a.97.97,0,0,0-.665.765.924.924,0,0,0,.417.9l5.02,3.353-6.977,2.359-2.588-1.95a1.088,1.088,0,0,0-1-.155l-2.523.853a1,1,0,0,0-.578.492.891.891,0,0,0-.034.728l1.607,4.1a5.45,5.45,0,0,0,2.96,3,6.085,6.085,0,0,0,4.376.178l6.146-2.078-1.325,4.835a.912.912,0,0,0,.305.941,1.072,1.072,0,0,0,.689.247,1.1,1.1,0,0,0,.35-.057l4.729-1.6a1,1,0,0,0,.589-.513l3.176-6.732L961,57.69a2.67,2.67,0,0,0,1.747-3.481Zm-23.313-1.834,2.495-.843,5.774,2.655-3.689,1.247ZM960.3,55.9l-9.826,3.322a1,1,0,0,0-.588.513l-3.176,6.732-2.494.843,1.325-4.834a.91.91,0,0,0-.305-.942,1.072,1.072,0,0,0-.689-.246,1.1,1.1,0,0,0-.35.057l-7.968,2.694a3.906,3.906,0,0,1-2.808-.114A3.5,3.5,0,0,1,931.52,62l-1.255-3.207,1.013-.343,2.589,1.95a1.09,1.09,0,0,0,1,.156l18.983-6.419a9.072,9.072,0,0,1,6.524.265.829.829,0,0,1,.451.457A.8.8,0,0,1,960.3,55.9Zm0,0"
      transform="translate(-927.925 -49.498)"
    />
  </svg>
);

FlightIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

FlightIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 34.99,
  height: 20.22,
};

export default FlightIcon;
