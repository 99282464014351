/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import useAuth from 'providers/Auth/useAuth';

import './structure.scss';

import Menu from 'components/generics/Menu/Menu';
import Header from 'components/generics/Header/Header';

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const history = useHistory();

  const [navState, setNavState] = useState(false);

  useEffect(() => {
    if (!user) {
      try {
        jwtDecode(localStorage.getItem('jwt_token'));
      } catch (error) {
        history.push('/login');
      }
    }
  }, [user]);

  return (
    user && (
      <div className={`page${navState ? ' navExpand' : ''}`}>
        <Menu navState={navState} user={user} />
        <div>
          <Header connectionState={{ state: 'online' }} toggleMenu={() => setNavState(!navState)} navState={navState} />
          <div className="content">{children}</div>
        </div>
      </div>
    )
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ProtectedRoute;
