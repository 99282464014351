import React from 'react';
import PropTypes from 'prop-types';

const MissionIcon = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 38.607 38.605">
    <path
      d="M19.976 38.605a18.629 18.629 0 10-8.552-35.176l-.167.086L8.002.26a.886.886 0 00-.629-.261.853.853 0 00-.19.021.888.888 0 00-.645.56L4.92 4.92.579 6.536A.892.892 0 00.26 8.001l3.256 3.255-.087.166a18.625 18.625 0 0016.547 27.182zM7.721 2.505l4.24 4.239-.607 3.354L6.647 5.39zm12.255 23.433a5.958 5.958 0 10-3.361-10.875l-.176.121-3.239-3.24.224-.18a10.509 10.509 0 11-3.96 8.216 10.4 10.4 0 012.3-6.552l.18-.224 3.238 3.239-.12.176a5.955 5.955 0 004.917 9.32zm.63-6.588l-2.895-2.9.306-.162a4.182 4.182 0 11-1.729 1.728l.163-.306 2.895 2.9a.912.912 0 001.259 0 .892.892 0 00.001-1.264zM2.506 7.726L5.393 6.65l4.708 4.707-3.354.608zm2.183 5.19l.154-.334.973.973a.9.9 0 00.79.247l3.08-.559-.287.484a12.307 12.307 0 104.329-4.328l-.485.288.559-3.083a.9.9 0 00-.247-.789l-.972-.972.333-.155a16.86 16.86 0 11-8.228 8.229z"
      fill={fill}
      color={color}
    />
  </svg>
);

MissionIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

MissionIcon.defaultProps = {
  color: '#000',
  fill: '#03a9f4',
  width: 38.607,
  height: 38.605,
};

export default MissionIcon;
