import React from 'react';
import PropTypes from 'prop-types';

const StatsIcon = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 35 35">
    <g id="stats" transform="translate(0)" color={color} fill={fill}>
      <path
        d="M1579.062,41.16a1.027,1.027,0,0,0-1.336.977v6.055a1.031,1.031,0,0,0,.538.911,8.315,8.315,0,1,1-11.288,11.288,1.035,1.035,0,0,0-.909-.538h-6.057a1.027,1.027,0,0,0-.977,1.337,15.971,15.971,0,1,0,20.029-20.03Zm.715,6.456v-4a13.918,13.918,0,0,1,8.371,11.758h-3.566A10.368,10.368,0,0,0,1579.777,47.616ZM1561.485,61.9h4a10.367,10.367,0,0,0,7.755,4.805v3.566A13.917,13.917,0,0,1,1561.485,61.9Zm13.809,8.371V66.709a10.383,10.383,0,0,0,9.289-9.289h3.565A13.939,13.939,0,0,1,1575.293,70.275Z"
        transform="translate(-1555.237 -37.364)"
        color={color}
      />
      <path
        d="M1572.243,53.345V35.338a1.026,1.026,0,0,0-1.025-1.025,19.021,19.021,0,0,0-19.031,19.032,1.025,1.025,0,0,0,1.025,1.025h18.006A1.026,1.026,0,0,0,1572.243,53.345Zm-2.051-1.025h-15.926a16.975,16.975,0,0,1,15.926-15.925Z"
        transform="translate(-1552.187 -34.313)"
        fill={fill}
        color={color}
      />
    </g>
  </svg>
);

StatsIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

StatsIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 35,
  height: 35,
};

export default StatsIcon;
