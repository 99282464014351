import { NavLink } from 'react-router-dom';

import './tab.scss';

const Tab = ({ title, path, url }: { title: string; path: string; url: string }) => (
  <NavLink className="tab" to={`${url}/${path}`}>
    {title}
  </NavLink>
);

export default Tab;
