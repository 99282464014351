import ForgotPasswordForm from 'components/pages/ForgotPassword/ForgotPasswordForm';

import './forgotPassword.style.scss';
import logo from 'assets/images/cps_logo.png';

const ForgotPassword = () => (
  <div className="forgotPassword">
    <div className="form">
      <div className="logoContainer">
        <img className="logo m-auto d-block" src={logo} alt="logo cps" />
      </div>
      <div className="container">
        <h1>Forgot password</h1>
        <ForgotPasswordForm />
      </div>
    </div>
    <div className="img" />
  </div>
);

export default ForgotPassword;
